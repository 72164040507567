import React, { useEffect, useReducer, useState } from "react";
import { withKeyAndJsonHeaders } from "../api";

export function LoadingSpinner() {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export enum LoadingState {
    LOADING,
    ERROR,
    SUCCESS
}

interface LoadingProps {
    state: LoadingState;
    children?: React.ReactNode;
}

export function Loading(props: LoadingProps) {
    switch (props.state) {
        case LoadingState.LOADING:
            return <LoadingSpinner />
        case LoadingState.ERROR:
            return (
                <>
                    Error loading data
                </>
            );
        case LoadingState.SUCCESS:
            return (
                <>
                    {props.children}
                </>)
    }
}

export interface TypedInitial<Result> {
    state: "initial";
    data?: Result;
}

export interface TypedSuccessful<Result> {
    state: "success";
    data: Result;
}

export interface TypedPending {
    state: "pending";
    message?: string;
}
export interface TypedLoading<Result> {
    state: "loading";
    message?: string;
    data: Result;
}

export interface TypedError<Result, Error> {
    state: "error",
    data?: Result,
    error: Error
}

export type TypedLoadingState<Result, Error, Saving = Result> = TypedSuccessful<Result> | TypedPending | TypedLoading<Saving> | TypedError<Result, Error> | TypedInitial<Result>;

export interface TypedLoadingProps<Result, Error, Saving> {
    render: (r: Result, e?: Error) => React.ReactNode;
    renderInitial?: (r?: Result) => React.ReactNode;
    error?: (e: Error) => React.ReactNode;
    state: TypedLoadingState<Result, Error, Saving>;
}

export function TypedLoading<Result, Error, Saving = Result>(props: TypedLoadingProps<Result, Error, Saving>) {
    switch (props.state.state) {
        case "pending":
        case "loading":
            return (
                <>
                    <div className="text-center">
                        <LoadingSpinner />
                        Laden...
                        <div>{props.state.message && (<>Status: {props.state.message}</>)}</div>
                    </div>
                </>
            );
        case "error":
            return (
                <>
                    Fehler beim Laden der Daten
                    {props.error?.(props.state.error)}
                </>
            );
        case 'initial':
            return (
                <>
                    {props.state.data && props.render(props.state.data)}
                </>
            )
        case "success":
            return (
                <>
                    {props.render(props.state.data)}
                </>)
    }
}
export interface useRemoteContentProps<Result> {
    url?: string;
    render: (result: Result, dispatch: () => void) => React.ReactNode;
    initial: Result;
    reducer: () => Result;
}

export function RemoteContent<Result, Error>(props: useRemoteContentProps<Result>) {
    const [state, setState] = useState<TypedLoadingState<Result, Error>>({ state: 'pending' });
    const [localState, dispatch] = useReducer(props.reducer, props.initial);
    useEffect(() => {
        const abortController = new AbortController();
        fetch(
            "/api/v1/dashboard",
            withKeyAndJsonHeaders({
                // Add corresponding method depending on state: GET, POST, PUT, DELETE
                signal: abortController.signal,
                body: JSON.stringify(localState)
            }),
        )
            .then(async (response) => {
                const data: Result = await response.json();
                setState({ state: 'success', data });
            });
        return () => {
            abortController.abort();
        }
    }, [state]);
    switch (state.state) {
        case "pending":
        case "loading":
            return (
                <>
                    <div className="text-center">
                        <LoadingSpinner />
                        Laden...
                        Nachricth: {state.message}
                    </div>
                </>
            );
        case "error":
            return (
                <>
                    Fehler beim Laden der Daten
                </>
            );
        case "success":
            return (
                <>
                    {props.render(state.data, dispatch)}
                </>
            );
        default:
            return (
                <>{state.state}</>
            )

    }
}