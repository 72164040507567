/**
 * @author Steffen Kittel
 */
import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { NavLink } from 'react-router-dom';

import { Project, Xist, withKeyAndJsonHeaders } from "../../api";
import { DnDItemTypes } from '../ProjectPresenter';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { XistStatus } from './XistList';

interface getTreeFromFlatDataProps {
    flatData: Xist[];
    getKey: (xist: Xist) => number;
    getParentKey: (xist: Xist) => number | null;
    rootKey: number | null;
    contain?: (item: any) => boolean;
}

function getTreeFromFlatData(props: getTreeFromFlatDataProps): Xist[] {
    let nodes: Xist[] = [];
    props.flatData.forEach((node: Xist) => {
        let parent_key = node.parent_id;
        if (parent_key === props.rootKey) {
            let keep = props.contain ? props.contain(node) : true;
            if (keep === true) {
                nodes.push(node);
            }
        }
    });
    nodes.forEach((node: Xist) => {
        const children = getTreeFromFlatData({ flatData: props.flatData, getKey: props.getKey, getParentKey: props.getParentKey, rootKey: node.id, contain: props.contain });
        node.children = children;
    });
    return nodes;
}
interface XistTreeProps {
    className: string;
    project: Project;
}
export function XistTree({ className, project }: XistTreeProps) {
    const abortController = useRef<AbortController>(new AbortController());

    const projectId = project.id;
    const [showArchived, setShowArchived] = useLocalStorage(`showArhived-tree-${project.id}`, false);
    const [showCompleted, setShowCompleted] = useLocalStorage(`tree-${projectId}-showCompleted`, true);
    const tree = useMemo(() => {
        const contain = (node: any) => {
            if (node.id === 0) {
                return false;
            }
            const isArchived = node.archived_at !== null;
            const isCompleted = (node.status === XistStatus.DONE || node.directory == true);

            if (node.directory === true) {
                return true;
            }

            if (!showArchived && isArchived) {
                return false;
            }

            if (!showCompleted && isCompleted) {
                return false;
            }

            return true;
        };
        const tree =
        {
            id: 0,
            xist_id: 0,
            directory: true,
            name: 'Xists',
            parent_id: null,
            status: 0,
            files: [],
            children: getTreeFromFlatData({
                flatData: project.tasks,
                getKey: (node: Xist) => node.xist_id,
                getParentKey: (node: Xist) => node.parent_id,
                rootKey: null,
                contain: contain
            })
        };
        return tree;
    }, [showCompleted, showArchived, project]);
    const onMoveNode = (task_id: number, new_parent_id: number) => {
        abortController.current.abort()
        const newAbortController = new AbortController();
        abortController.current = newAbortController;
        let rename_request = {
            parent_id: new_parent_id ? new_parent_id : 0,
            id: task_id
        };
        fetch(`/api/v1/project/${projectId}/tasks/${task_id}`, withKeyAndJsonHeaders({
            signal: newAbortController.signal,
            method: 'PUT',
            body: JSON.stringify(rename_request)
        })).then(
            async () => {
                //update();
            }
        ).finally(() => {
            console.debug("finally do something!");
        })
    }

    if (tree === null) {
        return (
            <div className={`d-flex justify-content-center ${className}`}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
    console.debug("tree is ", tree)
    return (
        <div className={className}>
            <div className="text-end">
                <label>Archivierte anzeigen</label> <input type='checkbox' checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                <br />
                <label>Erledigte anzeigen</label> <input type='checkbox' checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)} />
            </div>
            <Tree tree={tree} projectId={projectId} onMoveNode={onMoveNode} />
        </div>
    );
}
interface TreeProps {
    tree: any;
    projectId: number;
    onMoveNode: (task_id: number, new_parent_id: number) => void;
}
function Tree({ tree, projectId, onMoveNode }: TreeProps) {
    const [isOpen, setIsOpen] = useLocalStorage(`isOpen-${projectId}-${tree.id}`, true);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }
    const [{ opacity }, dragRef] = useDrag(() => ({
        type: DnDItemTypes.XIST,
        item: { id: tree.id, parent_id: tree.parent_id, name: tree.name },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
        })
    }), []);
    const [{ hover, name, canDrop }, dropRef] = useDrop(() => ({
        accept: DnDItemTypes.XIST,
        drop: (e: any) => {
            console.debug("dropping", e.id, "to", tree.id, e.name);
            onMoveNode(e.id, tree.id);
        },
        canDrop: (_item: any, _monitor: any) => tree.directory,
        collect: (monitor) => ({
            hover: monitor.isOver({ shallow: true }),
            name: monitor.getItem()?.name,
            canDrop: tree.directory
        })
    }), []);

    let textColor = "";
    switch (tree.status) {
        case 0:
            textColor = "text-primary";
            break;
        case 1:
            textColor = "text-warning";
            break;
        case 2:
            textColor = "text-muted";
            break;
        default:
            textColor = "text"
    }
    return (
        <div key={tree.id} className="list-group list-group-flush">
            <div ref={dropRef} style={{ backgroundColor: (hover && canDrop) ? 'lime' : '' }} className="d-flex justify-content-between align-items-center">
                <div className='d-flex'>
                    {tree.directory === true ?
                        <i onClick={toggleOpen} className="material-icons">{isOpen ? "chevron_right" : "expand_more"}</i>
                        : <span className='d-inline-block' style={{ width: '24px', minWidth: '24px' }}></span>
                    }
                    {(tree.id === 0) ? <NavLink to={`/project/${projectId}/xist`}>Xisties</NavLink> : <NavLink to={`/project/${projectId}/xist/${tree.id}`} ref={dragRef} style={{ opacity }} className={`d-inline-block text-truncate ${textColor}`}>{tree.name}</NavLink>}
                </div>
                {tree.directory === true &&
                    <span className="badge badge-primary badge-pill" style={{ background: 'lightblue' }}>{tree.children.length}</span>
                }
            </div>
            <div style={{ display: (hover && canDrop) ? 'block' : 'none', fontSize: '10px' }}>
                - {name}
            </div>
            {
                !isOpen &&
                <div className='ms-3'>
                    {tree.children.map((child: any) => {
                        return (
                            <Tree key={child.id} tree={child} projectId={projectId} onMoveNode={onMoveNode} />
                        )
                    })
                    }
                </div>
            }
        </div >
    )
}