import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import NavbarIconWhite from "../../assets/img/logo.png";

import { ProjectList as ProjectListPresenter } from "./projectList/ProjectListPresenter";
import Dashboard from "./dashboard/DashboardPresenter";

import NewProject from "./project/NewProject";
import ProjectPresenter from "./project/ProjectPresenter";
import { ProfilePresenter } from './profile/ProfilePresenter';
import { SearchBar, SearchBarProps, SearchResults } from "./search/Search";
import { RTC } from "./rtc/RTC";

import { connection } from "./api";
import { BetaContext, EnvironmentContext } from "./greeter/Greeter";
import incomingCallSound from "../incoming_call.m4a";
//import { useTranslation } from "react-i18next";

interface Xist {
    id: number;
}

interface Props {
    onClickLogout: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function Xistics({ onClickLogout }: Props) {
    const environment = useContext(EnvironmentContext);
    const beta = useContext(BetaContext);
    const navigate = useNavigate();
    //const [t, _i8n] = useTranslation<any>();
    const [openXists, setOpenXists] = useState<Xist[] | null>(null);
    const [gettingCalled, setGettingCalled] = useState<boolean>(false);
    const [otherSid, setOtherSid] = useState<string | null>(null);
    const search_match_pattern = '/search/:query';

    useEffect(() => {
        let data = localStorage.getItem("openXists");
        if (data) {
            let openXists = JSON.parse(data);
            if (openXists) {
                setOpenXists(openXists);
            }
            //setOpenXists(JSON.parse(localStorage.getItem("openXists")) || {});
        }
    }, []);

    useEffect(() => {
        connection.on('incoming_call', incomingCallCallback);
        connection.on('reconnect', onSocketReconnect);
        connection.sock().emit('enable_conference', environment?.user.id.toString());
        return () => {
            connection.off('incoming_call', incomingCallCallback);
            connection.on('reconnect', onSocketReconnect);
        }
    }, [])

    const onSocketReconnect = (_attemptNumber: number) => {
        connection.sock().emit('enable_conference', environment?.user.id.toString());
    }

    const incomingCallCallback = (otherSid: string) => {
        console.debug("incoming call from ", otherSid)
        setGettingCalled(true);
        setOtherSid(otherSid);
    }

    const acceptCall = () => {
        setGettingCalled(false);
        setOtherSid(null);
        navigate(`/rtc/${otherSid}`);
    }


    const onClickOpenXist = (xist: Xist) => {
        const openXists_ = openXists;
        if (openXists_ != null) {
            openXists_[xist.id] = xist;
            localStorage.setItem("openXists", JSON.stringify(openXists_));
            setOpenXists(openXists_);
        }
        return false;
    }

    const onChangeSearchQuery = (query: string) => {
        const encodedQuery = encodeURIComponent(query);
        navigate(`/search/${encodedQuery}`, { replace: true })
    }

    const onClearSearchQuery = () => {
        navigate(-1);
    };

    return (
        <React.Fragment>
            {gettingCalled &&
                <div className="position-absolute " style={{ zIndex: 10000, left: 0, right: 0 }}>
                    <audio src={incomingCallSound} autoPlay={true} loop={true} />
                    <button className="btn btn-warning w-100" onClick={acceptCall}>
                        Anruf annehmen
                    </button>
                </div>
            }
            <Navbar onClickLogout={onClickLogout} searchBarProps={{ onChangeQuery: onChangeSearchQuery, onClear: onClearSearchQuery, matchPattern: search_match_pattern }} />
            <div className="container-fluid d-flex flex-column">
                <SearchBar matchPattern={search_match_pattern} className="d-none d-lg-block" onChangeQuery={onChangeSearchQuery} onClear={onClearSearchQuery} />
                <Routes>
                    <Route path={search_match_pattern} element={<SearchResults />} />
                    <Route path="/" element={<Dashboard onClickOpenXist={onClickOpenXist} />} />
                    <Route path="/projectNew" element={<NewProject />} />
                    <Route path="/project/:projectId/*" element={
                        <ProjectPresenter onClickOpenXist={onClickOpenXist} beta={beta} />} />
                    <Route path="/profiles/me" element={<ProfilePresenter />} />
                    <Route path="/rtc/:otherSid?" element={<RTC />} />
                </Routes>
            </div>
        </React.Fragment>
    );
}

interface NavbarProps {
    onClickLogout: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    searchBarProps: SearchBarProps
}

function Navbar(props: NavbarProps) {
    const pins = <span style={{ color: 'white' }}>This is BETA!!!</span>;
    const onClick = () => { $('#navbarMain').removeClass('show') }
    const beta = useContext(BetaContext);

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="d-flex flex-row w-100">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMain" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="flex-grow-1 mx-2">
                    <SearchBar matchPattern={props.searchBarProps.matchPattern} className="d-block d-lg-none" onChangeQuery={props.searchBarProps.onChangeQuery} onClear={props.searchBarProps.onClear} />
                </div>
            </div>
            <div className="collapse navbar-collapse flex-column align-items-stretch p-2" id="navbarMain">
                <Link to="/" className="navbar-brand" onClick={onClick}>
                    <img src={NavbarIconWhite} className="d-inline-block align-top border border-light"
                        style={{ backgroundColor: "#fff", filter: "invert(100%)" }} height="30" width="30" />
                    <span className="px-2 py-auto">Übersicht</span>
                </Link>
                <div className="navbar-nav flex-fill" id="navbarNavDropdown">
                    {beta && pins}
                    <hr className="sidebar-divider"></hr>
                    <ul className="navbar-nav mr-auto flex-fill project-list-collapse">
                        <span className="navbar-brand">
                            Projekte
                        </span>
                        <ProjectListPresenter onItemClick={onClick} />
                    </ul>
                    <ul style={{ listStyle: 'none', paddingLeft: '0', marginRight: '0', width: '100%' }}>
                        <hr className="sidebar-divider"></hr>
                        <li className="nav-item">
                            <span data-toggle="collapse"
                                data-target="#navbarNavDropdown">
                                <Link to="/profiles/me" className="d-inline-block d-sm-none nav-link pl-0"
                                    target="_blank" style={
                                        {
                                            paddingTop: '0.3125rem',
                                            paddingBottom: '0.3125rem'
                                        }
                                    }>
                                    <Profile />
                                </Link>
                            </span>
                            <Link to="/profiles/me" className="d-none d-sm-inline-block nav-link p-0"
                                target="_blank" style={
                                    {
                                        paddingTop: '0.3125rem',
                                        paddingBottom: '0.3125rem'
                                    }
                                }
                                onClick={onClick}>
                                <Profile />
                            </Link>
                            <Link to="/rtc" className="float-end phone-button" target="_blank" onClick={onClick}>
                                <i className="material-icons" style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                }}>phone</i>
                            </Link>
                        </li>
                        <hr className="sidebar-divider"></hr>
                        <li className="nav-item">
                            <a href="#/" className="logout nav-link p-0" onClick={props.onClickLogout}>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export function Profile() {
    const environment = useContext(EnvironmentContext);
    let profile = "Mein Profil";
    if ("" !== environment?.user.surname || "" !== environment?.user.name) {
        profile = environment?.user.surname + " " + environment?.user.name;
    }
    return (
        <>
            {profile}
        </>
    );
}