/**
 * @author Steffen Kittel
 */

import React, { useEffect, useState } from 'react';

import { withKeyAndJsonHeaders } from '../api';
import { Login } from './Login';
import { ContactDetails } from './Profile';
import { TypedLoading, TypedLoadingState } from '../utils/Loading';
import Beta from './Beta';

export interface Profile {
    id: number;
    surname: string;
    name: string;
    street: string;
    zip: string;
    city: string;
    mobile: string;
    phone: string;
    fax: string;
    email: string;
}

export function ProfilePresenter() {
    const [state, setState] = useState<TypedLoadingState<Profile, string>>({ state: 'pending' });

    useEffect(() => {
        const abortController = new AbortController();
        switch (state.state) {
            case 'pending':
                fetch('/api/v1/user', withKeyAndJsonHeaders({ signal: abortController.signal })).then(
                    (response) => {
                        return response.json();
                    }).then(
                        response => {
                            let user = response;
                            setState({ state: 'success', data: user });
                        }
                    ).catch((e: DOMException) => {
                        if (e.name !== 'AbortError') {
                            setState({ state: 'error', error: e.message });
                        }
                    });
                break;
            case 'loading':
                fetch(`/api/v1/user/${state.data.id}`, withKeyAndJsonHeaders({
                    method: 'PUT',
                    signal: abortController.signal,
                    body: JSON.stringify(state.data)
                })).then(
                    async _response => {
                        setState({ state: 'pending' });
                    }
                );
                break;
        }
        return () => {
            abortController.abort();
        }
    }, [state]);

    return (
        <TypedLoading state={state} render={(profile) => {
            return (
                <div className="row">
                    <div className="col-12 profile profile-panel">
                        <div className="row">
                            <h1 className="col-12">Mein Profil</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <Login email={profile.email} />
                                </div>
                                <div className="row">
                                    <Beta />
                                </div>
                            </div>

                            <ContactDetails profile={profile} saveProfile={(profile: Profile) => { setState({ state: 'loading', data: profile }); }} />
                        </div>
                    </div>
                </div>
            );
        }} />
    );
}