/**
 * @author Steffen Kittel
 */

import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import Moment from "react-moment";

import { connection, Project, withKeyAndJsonHeaders, Xist } from "../api";
import Dashboard from "../dashboard/DashboardPresenter";
import { XistsTab } from "./xist/XistTab";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";
//import { useTranslation } from 'react-i18next';

import {
    Routes,
    Route,
    useParams,
    NavLink,
} from "react-router-dom";
import { SettingsTab } from "./administration/SettingsTab";
import { TeamTab } from "./team/TeamTab";
import { ProjectContextType } from "./ProjectContext";
import { EnvironmentContext } from "../greeter/Greeter";

export enum DnDItemTypes {
    XIST = "xist",
}

export const ProjectContext = createContext<ProjectContextType>(null);

interface ProjectProps {
    beta: boolean;
    onClickOpenXist: any;
}

export default function ProjectPresenter(props: ProjectProps) {
    const params = useParams<string>();
    //const [t, i18n] = useTranslation();
    const { onClickOpenXist } = props;
    const environment = useContext(EnvironmentContext);
    const [isProjectLead, setIsProjectLead] = useState<boolean>(false);
    const abortController = useRef(new AbortController);
    /*const [project, setProject] = useState<ProjectContextType>({
        kind: ProjectState.INITIALIZED,
    });*/
    const [project, setProject] = useState<TypedLoadingState<Project, string>>({ state: 'pending' });
    const [_xists, setXists] = useState<Xist | null>();
    //const [tabContent, setTabContent] = useState();
    if (params.projectId == undefined) {
        return <div>Ungültige Projekt ID!</div>;
    }
    const projectId = parseInt(params.projectId);

    const update = () => {
        abortController.current.abort();
        const newAbortController = new AbortController();
        abortController.current = newAbortController;
        fetch(
            `/api/v1/project/${projectId}`,
            withKeyAndJsonHeaders(newAbortController.signal),
        ).then(async (response) => {
            const result = await response.json();
            const isProjectLead = result.project.users.reduce(
                (isProjectLead: any, user: any) => {
                    if (isProjectLead) {
                        return true;
                    }
                    return user.id == environment?.user.id && user.role == 1;
                },
                false,
            );
            setIsProjectLead(isProjectLead);
            setProject({
                state: 'success',
                data: result.project,
            });
            setXists(result.project.tasks);
        }).catch((_reason: any) => {
            //console.debug("abort with reason", reason);
        });
    };

    useEffect(() => {
        const eventName = `update-project-${projectId}`;
        connection.on(eventName, update);
        setProject({ state: 'pending' });
        update();
        return () => {
            connection.off(eventName, update);
            abortController.current.abort();
        };
    }, [projectId]);

    return (
        <TypedLoading state={project} render={(project) => {
            const readOnly = project.closed !== false;
            return (
                <ProjectContext.Provider value={project}>
                    <div className="row h-100" key={project.id}>
                        <div className="col-12 h-100 d-flex flex-column">
                            <div className="clearfix">
                                <span className="h1 float-start">
                                    {project.name}{" "}
                                    {project.closed && (
                                        <span className="small text-muted">
                                            {"Archiviert am"}:{" "}
                                            <Moment format="DD.MM.YYYY">
                                                {project.closed}
                                            </Moment>
                                        </span>
                                    )}
                                </span>
                                <ul className="nav nav-tabs float-end">
                                    <NavLink className="nav-link" to="dashboard">
                                        Übersicht
                                    </NavLink>
                                    <NavLink to="xist" className="nav-link" >
                                        Arbeitspakete und Dokumentation
                                    </NavLink>
                                    <NavLink className="nav-link" to="team">
                                        Team
                                    </NavLink>
                                    {isProjectLead && (
                                        <NavLink className="nav-link" to="settings">
                                            Einstellungen
                                        </NavLink>
                                    )}
                                </ul>
                            </div>
                            <div
                                className="row flex-grow-1"
                                style={{ height: "0px" }}
                            >
                                <div className="col-12 d-flex flex-column h-100">
                                    <div className="row flex-fill h-100">
                                        <div className="col-12 tab-content d-flex flex-column h-100">
                                            <Routes>
                                                <Route
                                                    path="dashboard"
                                                    element={<Dashboard onClickOpenXist={onClickOpenXist} projectId={projectId} />}
                                                />
                                                <Route
                                                    path="xist/:xistId?/*"
                                                    element={<XistsTab isProjectLead={isProjectLead} readOnly={readOnly} />}
                                                />
                                                <Route
                                                    path="settings"
                                                    element={<SettingsTab project={project} isProjectLead={isProjectLead} readOnly={readOnly} />}
                                                />
                                                <Route
                                                    path="team"
                                                    element={<TeamTab project={project} isProjectLead={isProjectLead} readOnly={readOnly} />}
                                                />
                                            </Routes>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ProjectContext.Provider>
            );
        }} />
    );
}
