import React from "react";
import { LogListItemTask, LogListItemTaskType } from "./items/LogListItemTask";
import { LogListItemComment } from "./items/LogListItemComment";
import { Author } from "./items/Common";
import { Link } from "react-router-dom";
import Moment from "react-moment";
//import { useTranslation } from "react-i18next";

interface LogListProps {
    logs: any;
    onClickOpenXist: any;
    onNavigate?: any;
    reload: any;
    tooltip: any;
    onClickRead: (log_id: number) => void;
}

export type LogEntryTask = LogListItemTaskType & {
    type: "task";
}

export type LogEntryTaskComment = {
    type: "task_comment";

    author: Author,
    comment_id: number;
    comment_text: string;
    project_name: string;
    task_id: number;
    task_name: string;
}

export type LogEntryData = LogEntryTask | LogEntryTaskComment;

export interface LogEntry {
    id: number;
    project_id?: number;
    date: Date;
    data: LogEntryData
}

export function LogList({
    logs,
    onClickOpenXist,
    reload,
    tooltip,
    onClickRead
}: LogListProps) {
    //const [t, i18n] = useTranslation();
    return (
        <div className="col-md mh-100 overflow-auto">
            <div className="row">
                <div className="col d-flex align-items-baseline">
                    <h3 className="pe-2"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={tooltip}
                    >
                        Updates
                    </h3>
                    {(!logs || logs.length == 0) && (
                        <small>Keine ungelesenen Aktivitäten</small>
                    )}
                </div>
            </div>
            {logs && logs.length > 0 && (
                <div className="row">
                    <div className="col">
                        {
                            logs.map((log: LogEntry) => {
                                let content = <>"Undefinierter Logeintrag"</>;
                                switch (log.data.type) {
                                    case 'task':
                                        content = (
                                            <LogListItemTask
                                                key={log.id.toString()}
                                                reload={reload}
                                                task={log.data}
                                                onClickOpenXist={onClickOpenXist}
                                            />
                                        );
                                        break;
                                    case 'task_comment':
                                        content = <LogListItemComment key="log.id.toString()" log={log.data} projectId={log.project_id} />
                                }
                                return (
                                    <div key={log.id.toString()}>
                                        <div className="mb-3 rounded p-3 shadow-sm">
                                            {content}
                                            <div className="row">
                                                <div className="col-12 clearfix">
                                                    <Moment
                                                        className="small text-muted float-start"
                                                        locale="de"
                                                        fromNow
                                                    >
                                                        {log.date}
                                                    </Moment>
                                                    <Link
                                                        to="/dashboard"
                                                        onClick={(e: any) => { e.preventDefault(); onClickRead(log.id); }}
                                                        className="small text-muted float-end"
                                                    >
                                                        gelesen
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            )}
        </div>
    );
}
