/**
 * @author Steffen Kittel
 */

import React, { useEffect, useState } from 'react';
import { Loading, LoadingState } from '../utils/Loading';
//import { useTranslation } from 'react-i18next';

export default function Beta() {
    //const [t, _i18n] = useTranslation<any>();
    const [beta, setBeta] = useState<boolean>(false);
    const [loading, setLoading] = useState<LoadingState>(LoadingState.SUCCESS);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        console.debug(checked)
        setBeta(checked);
    }

    const onSubmit = (_e: React.FormEvent<HTMLFormElement>) => {
        setLoading(LoadingState.LOADING);
        if (beta) {
            localStorage.setItem("beta", "true");
        } else {
            localStorage.removeItem("beta");
        }
        setTimeout(() => {
            setLoading(LoadingState.SUCCESS);
        }, 1000)
    }

    useEffect(() => {
        const beta = localStorage.getItem("beta");
        setBeta(beta === null ? false : beta === "true" ? true : false);
    }, []);

    return (
        <div className="col">
            <Loading state={loading}>
                <form className="form-horizontal" onSubmit={onSubmit}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={beta} onChange={onChange}></input>
                        <label className="control-label" htmlFor="input-street">An Beta Programm teilnehmen</label>
                    </div>
                    <button type="submit" className="btn btn-success">Speichern</button>
                </form>
            </Loading>
        </div>
    )
}