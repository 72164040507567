
/**
 * @author Steffen Kittel
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { XistFile, connection, withKeyAndJsonHeaders } from '../../api';

import { IssueVersion, MyXist, VersionId, XistList } from './XistList';

import { XistProperties } from './properties/XistProperties';
import { XistComments } from './comments/XistComments';
import moment from 'moment';

import { BetaContext } from '../../greeter/Greeter';
import { ImageGallery } from '../../gallery/ImageGallery';
import { Editor } from '../../../ui/components/Editor';
import { XistFiles } from './files/XistFiles';

interface XistProps {
    readOnly: boolean;
    onClickOpenXist: any;
    project: any;
    isProjectLead: boolean;
    remoteXist: MyXist;
    version: VersionId | null;
    setVersion: (version: VersionId | null) => void;
    saveXist: (xist: MyXist) => void;
}

export function Xist({ remoteXist, saveXist, readOnly, onClickOpenXist, project, isProjectLead, version, setVersion }: XistProps) {
    const beta = useContext(BetaContext);
    const [preview, setPreview] = useState<XistFile | null>(null);
    const [changed, setChanged] = useState<boolean>(false);
    const [xist, setXist] = useState(remoteXist);
    const [comments, setComments] = useState(xist.comments);
    const abortController = useRef<AbortController>(new AbortController);

    let isLatestVersion = false;
    if (xist && xist.versions) {
        const latest_version = Math.max.apply(Math, xist.versions.map((o: IssueVersion) => o.version_id));
        if (xist.version == latest_version) {
            isLatestVersion = true;
        }
    }

    const onChangeVersion = (e: React.ChangeEvent<HTMLInputElement>) => {
        const version = e.target.value;
        setVersion(parseInt(version));
    }

    const onDateChange = (date_: string) => {
        const date = moment(date_);
        if (xist !== null) {
            xist.deadline = date.format('DD.MM.YYYY');
        }
        setChanged(true);
        setXist({ ...xist, deadline: date.format('DD.MM.YYYY') });
    }

    const load_comments = () => {
        abortController.current.abort();
        const newController = new AbortController();
        abortController.current = newController;
        fetch(
            `/api/v1/project/${project.id}/tasks/${xist.xist_id}`,
            withKeyAndJsonHeaders({
                signal: abortController.current.signal,
            }),
        )
            .then(async (response) => {
                const xist = await response.json();
                setComments(xist.comments)
            })
            .catch(() => { });
    }

    useEffect(() => {
        connection.on(`update-xist-${xist.xist_id}-comments`, load_comments);
        return () => {
            connection.off(`update-xist-${xist.xist_id}-comments`, load_comments);
            abortController.current.abort();
        }
    }, []);

    const onToggleArchive = () => {
        saveXist({ ...xist, archived_at: xist.archived_at ? undefined : new Date() })
    }

    const onSave = () => {
        saveXist(xist);
    }

    const onChangeSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value: string | number | boolean = target.type == 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === "status" && typeof value === 'string') {
            value = parseInt(value);
        }
        if (name === "responsible" && typeof value === 'string') {
            value = parseInt(value);
        }
        setXist({ ...xist, [name]: value });
        setChanged(true);
    }

    const onFileUploadSuccess = () => {
        //refresh();
    }

    const onTextChange = (html: string, _delta: any, source: string) => {
        let changed = true;
        if (source === 'api') {
            changed = false;
        }
        if (xist !== null && xist.description == html) {
            changed = false;
        }
        setXist({ ...xist, description: html });
        setChanged(changed);
    }

    const onPreviewFile = (_file: any) => {
        //setPreview(true); ToDo: Fixme
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="col-12"><div>
                    <h2>
                        <TextMorphToEditable text={xist.name} onChange={onChangeSettings} />
                        <span className="float-end">
                            {beta && (
                                <button className="btn btn-link border-0 material-icons p-0"
                                    onClick={() => {
                                        onClickOpenXist({
                                            project_id: project.id,
                                            id: xist.id,
                                            name: xist.name
                                        });
                                    }}>
                                    star
                                </button>
                            )}
                            {!readOnly && changed &&
                                <Button variant="outline-success" className="float-end"
                                    onClick={onSave}>{"Änderungen speichern"}</Button>}
                            {!readOnly &&
                                <Button variant="outline-success" className="float-end"
                                    onClick={onToggleArchive}>{xist.archived_at ? "Wiederherstellen" : "Archivieren"}</Button>}
                        </span>
                    </h2>
                    {!isLatestVersion && (
                        <span className="text-warning">
                            Achtung! Sie bearbeiten gerade nicht die aktuellste Version!<br />M&ouml;gliche &Auml;nderungen in neueren Versionen werden ggf. &uuml;berschrieben!
                        </span>
                    )}
                </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-9">
                        <div className='row'>
                            {preview && <ImageGallery xist={xist} setPreview={setPreview} onClose={() => { setPreview(null); }} selected={preview} />}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Beschreibung</h4>
                                <Editor defaultValue={xist.description} onTextChange={onTextChange} readOnly={readOnly} />
                            </div>
                        </div>
                        <XistComments projectId={project.id} comments_={comments} xist={xist} collapsed={xist.directory} />
                        {xist.directory === true && (
                            <div className='col-12'>
                                <div className="row">
                                    <h3 className='h3'>Unterelemente</h3>
                                    <XistList
                                        onClickOpenXist={onClickOpenXist}
                                        xist={xist}
                                        project={project}
                                        isProjectLead={isProjectLead}
                                        readOnly={readOnly}
                                        beta={beta}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-3">
                        <div className='row'>
                            <XistProperties
                                key={xist.id}
                                project={project}
                                xist={xist}
                                className="col"
                                onChangeSettings={onChangeSettings}
                                onChangeVersion={onChangeVersion}
                                onDateChange={onDateChange}
                                onPreviewFile={onPreviewFile}
                                isProjectLead={isProjectLead}
                                readOnly={readOnly}
                                displayVersion={version}
                            />
                        </div>
                        <div className="row">
                            <XistFiles project={project} xist={xist} onFileUploadSuccess={onFileUploadSuccess} readOnly={readOnly} setPreview={setPreview} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function TextMorphToEditable({ text, onChange }: { text: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    const [editing, setEditing] = useState(false);
    return (
        <>{editing ?
            (<input type="text" name="name" value={text} onChange={onChange} onBlur={() => setEditing(false)}></input>) :
            (<span onClick={() => setEditing(!editing)}>{text}</span>)}</>
    )
}