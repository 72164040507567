import React from "react";
import {Link} from 'react-router-dom';

//import NavbarIconWhite from "../../../assets/img/logo.png";
const NavbarIconWhite = "icon_white";

export function RegistrationSuccessful() {
    return (
        <form className="form-horizontal">
            <div className="modal-dialog shadow-sm" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="welcome-h4 my-auto">Registrierung erfolgreich</h2><img
                        className="pull-right welcome-logo border border-dark" src={NavbarIconWhite}
                        style={{width: '48px', height: '48px'}}/>
                    </div>
                    <div className="modal-body">
                        Glückwunsch! Sie haben sich erfolgreich bei xistics registriert!
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn btn-primary">Zum Login</Link>
                    </div>
                </div>
            </div>
        </form>
    );
}