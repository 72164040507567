/**
 * @author Steffen Kittel
 */
import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { de } from 'date-fns/locale';
registerLocale('de', de);
setDefaultLocale('de');

//import { useTranslation } from 'react-i18next';
import { CloudDocuments } from '../cloud_documents/CloudDocuments';
import { XistStatus } from '../XistList';

interface XistPropertiesProps {
    className: string;
    displayVersion: any;
    onChangeSettings: any,
    onDateChange: any;
    onChangeVersion: any;
    readOnly: boolean;
    project: any;
    xist: any;
    onPreviewFile?: any;
    isProjectLead?: any;
}

export function XistProperties({ className, displayVersion, onChangeSettings, onDateChange, onChangeVersion, readOnly, project, xist }: XistPropertiesProps) {
    //const [t, i18n] = useTranslation();
    let users = project.users.map((user: any) => <option key={user.id} value={user.id}>{user.surname} {user.name} {user.name == "" && user.surname == "" && user.email}</option>);
    //let crafts = project.crafts.map((craft: any) => <option key={craft.id} value={craft.id}>{craft.name}</option>);

    return (
        <div className={className}>
            <div className="row">
                <div className="col-12">
                    <form>
                        <div className='mb-3'>
                            <label htmlFor={`version-select-${xist.id}`} className="form-label">Stand</label>
                            <select id={`version-select-${xist.id}`} className="form-select form-select-sm" name="status" value={displayVersion == null ? xist.version : displayVersion} onChange={onChangeVersion}>
                                {xist.versions !== undefined && xist.versions.map((version: any) => <option key={version.version_id} value={version.version_id}>{moment(version.timestamp).format("DD.MM.YYYY HH:mm:ss")}</option>)}
                            </select>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor={`creator-select-${xist.id}`} className="form-label">Erstellt durch</label>
                            <select id={`creator-select-${xist.id}`} className="form-select form-select-sm" value={xist.responsible_user} name="responsible_user" disabled>
                                {users}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor={`responsible-select-${xist.id}`} className="form-label">Zugewiesen an</label>
                            <select id={`responsible-select-${xist.id}`} className="form-select form-select-sm" value={xist.responsible} name="responsible" onChange={onChangeSettings} disabled={readOnly}>
                                {users}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor={`status-select-${xist.id}`} className="form-label">Status</label>
                            <select id={`status-select-${xist.id}`} className="form-select form-select-sm" name="status" value={xist.status} onChange={onChangeSettings} disabled={readOnly}>
                                <option value={XistStatus.PENDING}>offen</option>
                                <option value={XistStatus.IN_PROGRESS}>in Bearbeitung</option>
                                <option value={XistStatus.DONE}>fertiggestellt</option>
                            </select>
                        </div>
                        {xist.deadline &&
                            <div className="mb-3">
                                <label htmlFor={`deadline-${xist.id}`} className="form-label">Fälligkeit</label>
                                <DatePicker id={`deadline-${xist.id}`} className="form-control form-control-sm" dateFormat="dd.MM.yyyy" locale="de" selected={moment(xist.deadline, 'DD.MM.YYYY').toDate()} onChange={onDateChange} readOnly={readOnly} />
                            </div>
                        }
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <CloudDocuments project={project} xist={xist} />
                </div>
            </div>
        </div>
    );
}