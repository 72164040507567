/**
 * @author Steffen Kittel
 */

import React, { useEffect, useState } from "react";

import { connection, withKeyAndJsonHeaders } from "../api";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
//import { useTranslation } from 'react-i18next';

interface Project {
    id: number;
    name: string;
    closed: boolean;
    closed_date: Date;
}

interface ProjectListItemProps {
    projectId: number;
    projectName: string;
    onItemClick: () => void;
}

function ProjectListItem(props: ProjectListItemProps) {
    return (
        <React.Fragment>
            <span >
                <NavLink
                    to={`/project/${props.projectId}/xist`}

                    className={({ isActive, isPending }) =>
                        classNames("d-block text-nowrap", isPending ? "pending" : isActive ? "link-success" : "")
                    }
                    onClick={props.onItemClick}
                >
                    {props.projectName}
                </NavLink>
            </span>
        </React.Fragment>
    );
}
interface ProjectListProps {
    onItemClick: () => void
}
export function ProjectList({ onItemClick }: ProjectListProps) {
    //const [t, i18n] = useTranslation();
    const [projectsOpen, setProjectsOpen] = useState<Project[] | null>(null);
    const [projectsClosed, setProjectsClosed] = useState<Project[] | null>(
        null,
    );

    const update = () => {
        const abortController = new AbortController();
        fetch(
            "/api/v1/projects",
            withKeyAndJsonHeaders({ signal: abortController.signal }),
        ).then(async (response) => {
            const projects = await response.json();
            const open = projects.projects.filter(
                (project: any) => project.closed == false,
            );
            const closed = projects.projects.filter(
                (project: any) => project.closed == true,
            );
            setProjectsOpen(open);
            setProjectsClosed(closed);
        }).catch((e: DOMException) => {
            if (e.name !== "AbortError") {
                console.debug("is error!", e);
            }
        });
    };

    useEffect(() => {
        const eventName = "update-project-list";
        connection.on(eventName, update);
        update();
        return () => {
            connection.off(eventName, update);
        };
    }, []);

    return (
        <div className="project-list">
            <Link to="/projectNew">Neues Projekt</Link>
            <h6 className="text-muted pt-3">Aktiv</h6>
            {projectsOpen && (
                <>
                    {projectsOpen.map((project) => {
                        return (
                            <ProjectListItem
                                key={project.id}
                                projectName={project.name}
                                projectId={project.id}
                                onItemClick={onItemClick}
                            />
                        );
                    })}
                </>
            )}
            {(projectsClosed && projectsClosed.length > 0) && (
                <>
                    <h6 className="text-muted pt-3">Inaktiv</h6>
                    <a
                        role="button"
                        href="#"
                        className="btn btn-outline-primary btn-sm"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#projectsInactive"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                    >
                        Anzeigen
                    </a>
                    <div className="collapse" id="projectsInactive">
                        {projectsClosed && (
                            <>
                                {" "}
                                {projectsClosed.map((project) => (
                                    <ProjectListItem
                                        key={project.id}
                                        projectName={project.name}
                                        projectId={project.id}
                                        onItemClick={onItemClick}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
