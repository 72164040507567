/**
 * @author Steffen Kittel
 */

import React, { useEffect, useRef, useState } from "react";

import { withKeyAndJsonHeaders } from "../api";
import { DashboardXist, DashboardXistList } from "./DashboardXistList";
import { LogEntry, LogList } from "./log_list/DashboardLogList";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";
//import { useTranslation } from 'react-i18next';

interface Dashboard {
    logs: LogEntry[],
    tasks: DashboardXist[],
    todos: DashboardXist[]
}

interface MarkLogRead {
    log_id: number;
}

interface DashboardProps {
    onNavigate?: any;
    onClickOpenXist: (xist: DashboardXist) => void;
    projectId?: number;
}

export default function Dashboard(props: DashboardProps) {
    //const [t, i18n] = useTranslation();
    const { onNavigate, onClickOpenXist, projectId } = props;
    const [logs, setLogs] = useState<TypedLoadingState<LogEntry[], string, MarkLogRead>>({ state: 'pending' });
    const [current, setCurrent] = useState<TypedLoadingState<DashboardXist[], string>>({ state: 'pending' });
    const [todos, setTodos] = useState<TypedLoadingState<DashboardXist[], string>>({ state: 'pending' });
    const abortController = useRef<AbortController>(new AbortController());

    function load() {
        abortController.current = new AbortController();
        fetch(
            "/api/v1/dashboard",
            withKeyAndJsonHeaders({
                signal: abortController.current.signal,
            }),
        ).then(async (response) => {
            const data = await response.json();
            let { logs, todos, tasks: current } = data;
            if (projectId && logs && todos && current) {
                console.debug("logs are ", logs)
                logs = logs.filter((item: LogEntry) => item.project_id == projectId,);
                todos = todos.filter((item: any) => item.project_id == projectId,);
                current = current.filter((item: any) => item.project_id == projectId,);
            }
            setLogs({ state: 'success', data: logs });
            setTodos({ state: 'success', data: todos });
            setCurrent({ state: 'success', data: current });
        }).catch((error) => {
            console.debug("error", error);
        });
    }

    useEffect(() => {
        load();
        return () => {
            abortController.current.abort();
        };
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        switch (logs.state) {
            case 'loading':
                fetch(
                    `/api/v1/log/${logs.data.log_id}`,
                    withKeyAndJsonHeaders({ method: "POST" }),
                ).then(() => {
                    load();
                });
                break;
        }
        return () => {
            abortController.abort();
        }
    }, [logs]);

    let todos_ = todos;
    let current_ = current;
    const onClickRead = (log_id: number) => {
        setLogs({ state: 'loading', data: { log_id: log_id } });
        console.debug("should mark ", log_id, " as read");
    }

    return (
        <Row className="row mh-100 overflow-auto">
            <Col>
                <Row>
                    <TypedLoading state={logs} render={(logs) => {
                        return (
                            <LogList
                                onClickRead={onClickRead}
                                logs={logs}
                                reload={load}
                                onNavigate={onNavigate}
                                onClickOpenXist={onClickOpenXist}
                                tooltip={
                                    'Hier werden alle Aktivitäten deiner Projektbeteiligten projektübergreifend angezeigt. Klicke auf "gelesen", damit diese Anzeige wieder verschwindet.'
                                }
                            />);
                    }} />
                </Row>
                <Row>
                    <TypedLoading state={todos_} render={(todos_) => {
                        return (
                            <DashboardXistList
                                data={todos_}
                                caption={"Meine Arbeitspakete"}
                                reload={load}
                                tooltip={
                                    "Hier werden projektübergreifend alle Arbeitspakete angezeigt, für die du als Verantwortlicher zugewiesen wurdest."
                                }
                                projectId={projectId}
                                {...props}
                            />);
                    }} />
                </Row>
                <Row>
                    <TypedLoading state={current_} render={(current_) => {
                        return (
                            <DashboardXistList
                                data={current_}
                                caption={"Alle Arbeitspakete"}
                                reload={load}
                                tooltip={
                                    "Hier werden alle Arbeitspakete projektübergreifend angezeigt, die als nächstes fällig sind, unabhängig davon wer als Verantwortlicher zugwiesen ist."
                                }
                                projectId={projectId}
                                {...props}
                            />);
                    }} />
                </Row>
            </Col>
        </Row>
    );
}
