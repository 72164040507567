import React from 'react';
import { Link } from 'react-router-dom';
import { MyXist } from './XistList';

interface BreadcrumbItemProps {
    children?: React.ReactNode;
}

function BreadcrumbItem(props: BreadcrumbItemProps) {
    return (
        <li className="breadcrumb-item">
            {props.children}
        </li>
    )
}

interface XistBreadcrumbsProps {
    onClickOpenXist?: any;
    projectId: number;
    xist: MyXist;
    xists: MyXist[];
}

const buildPath: any = (xist: MyXist, xists: MyXist[]) => {
    if (xist == null || xist.id == -1 || xist.id == null) {
        return [{ name: "Xists", id: null }];
    } else {
        let l = null;
        let x = null;
        for (let i = 0; i < xists.length; i++) {
            if (xists[i].id == xist.parent_id) {
                x = xists[i];
            }
        }
        l = buildPath(x, xists);
        l.push(xist);
        return l;
    }
}

export function XistBreadcrumbs({ projectId, xist, xists }: XistBreadcrumbsProps) {
    return (
        <div className="row">
            <div className="col-12">
                <ol className="breadcrumb">
                    {buildPath(xist, xists).map((item: MyXist) => {
                        let content: React.ReactNode = (item.xist_id == xist.xist_id) ? item.name : <Link to={(null == item.id) ? `/project/${projectId}/xist` : `/project/${projectId}/xist/${item.xist_id}`} > {item.name}</Link>;
                        return (
                            <BreadcrumbItem key={item.id} >
                                {content}
                            </BreadcrumbItem>
                        );
                    })}
                </ol>
            </div>
        </div>
    );
}
