import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BetaContext } from '../../../greeter/Greeter';
import { Author } from './Common';

export interface LogListItemTaskType {
    id: number;
    task_xist_id: number;
    project_id: number;
    task_name: string;
    event: string;
    date: string;
    author: Author;
}

export interface LogListItemTaskProps {
    onClickOpenXist: any;
    reload: any;
    task: LogListItemTaskType;
}

export function LogListItemTask({
    onClickOpenXist,
    task,
}: LogListItemTaskProps) {
    const beta = useContext(BetaContext);
    const { task_xist_id, project_id } = task;

    return (
        <div className="card-text">
            <Author author={task.author} /> hat <Link to={`/project/${project_id}/xist/${task_xist_id}`}>{task.task_name}</Link>{" "} {task.event}.
            {beta && (
                <button
                    className="btn btn-link material-icons border-0 p-0"
                    onClick={() => {
                        onClickOpenXist({
                            project_id: project_id,
                            id: task.task_xist_id,
                            name: task.task_name,
                        });
                    }}
                >
                    <i className="bi bi-star"></i>
                </button>
            )}
        </div>
    );
}