/**
 * @author Steffen Kittel
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';

import { withKeyAndJsonHeaders } from '../api'
import { TypedLoading, TypedLoadingState } from '../utils/Loading';

type ProjectRequest = {
    name: string;
    template: number | null;
}

export default function NewProject() {
    //const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<TypedLoadingState<ProjectRequest, string>>({ state: 'initial', data: { name: '', template: null } });
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        switch (state.state) {
            case 'initial':
                fetch('/api/v1/projects', withKeyAndJsonHeaders({ signal: abortController.signal })).then(
                    async (response) => {
                        const projects = await response.json();
                        setProjects(projects.projects);
                    }).catch(() => { });
                break;
            case 'loading':
                fetch('/api/v1/projects', withKeyAndJsonHeaders({
                    method: 'POST',
                    body: JSON.stringify({ name: state.data.name, template: state.data.template })
                })).then(
                    async response => {
                        const data = await response.json();
                        navigate(`/project/${data.project_id}/xist`);
                    }
                );
                break;
        }
        return () => {
            abortController.abort();
        }
    }, [state]);

    const handleTemplate = (e: React.FormEvent<HTMLSelectElement>) => {
        if (state.state === 'initial' && state.data) {
            setState({ state: 'initial', data: { ...state.data, template: parseInt(e.currentTarget.value) } });
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.state === 'initial' && state.data) {
            setState({ state: 'initial', data: { ...state.data, name: e.target.value } });
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (state.state === 'initial' && state.data) {
            setState({ state: 'loading', data: state.data });
        }
    }

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <TypedLoading state={state} render={(projectRequest, error) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {error && (<>Fehler {error}</>)}
                            <div className="form-group">
                                <label htmlFor="inputName">Projektname</label>
                                <input type="text" className="form-control form-control-sm" id="projectName" aria-describedby="emailHelp" placeholder={'Projektname'} onChange={handleChange} value={projectRequest.name} autoComplete="false" />
                                <small id="emailHelp" className="form-text text-muted">Name des neuen Projekts</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputTemplate">Aus Vorlage</label>
                                <select className="form-control form-control-sm"
                                    id="inputTemplate"
                                    aria-describedby="inputTemplate"
                                    placeholder="Vorlage"
                                    value={projectRequest.template?.toString()}
                                    onChange={handleTemplate}>
                                    <option id="-1">Ohne</option>
                                    {projects.map((project: any) => <option key={project.id} value={project.id}>{project.name}</option>)}
                                </select>
                            </div>
                            <button className="btn btn-success">Erstellen</button>
                        </form>
                    );
                }} />

            </div>
        </div>
    );
}