import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { User, withKeyAndJsonHeaders } from "../api";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";

interface Credentials {
    email: string;
    password: string;
}

interface Data {
    credentials: Credentials;
    error?: string
}

interface LoginProps {
    onLoginSuccessful: (user: User) => void;
}

export function Login({ onLoginSuccessful }: LoginProps) {
    const [state, setState] = useState<TypedLoadingState<Data, string>>({ state: 'initial', data: { credentials: { email: '', password: '' } } });
    const user = User.getInstance();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        if ((state.state === 'initial' || state.state === 'success') && typeof value === 'string' && state.data) {
            let credentials = { ...state.data.credentials, [name]: value };
            setState({ state: 'initial', data: { ...state.data, credentials } });
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        switch (state.state) {
            case 'loading':
                fetch(
                    `/api/v1/login`,
                    withKeyAndJsonHeaders({
                        method: "POST",
                        body: JSON.stringify(state.data.credentials),
                        signal: abortController.signal
                    }),
                ).then(async (response) => {
                    const result = await response.json();
                    if (result === "invalid") {
                        setState({ state: 'success', data: { ...state.data, error: 'Falsche E-Mail Adresse oder Passwort!' } });
                    } else {
                        const { key, user_id } = result;
                        user.key = key;
                        user.id = user_id;
                        onLoginSuccessful(user);
                    }

                }).catch(() => {
                    setState({ state: 'error', data: state.data, error: 'Falsche E-Mail Adresse oder Passwort!' });
                });
        }
        return () => {
            abortController.abort();
        }
    }, [state]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (state.state !== 'initial') {
            return;
        }
        if (state.data === undefined) {
            return;
        }
        setState({ state: 'loading', data: state.data });
    };

    return (
        <div className="col-md-4 mx-auto py-5">
            <div className="mx-auto d-flex align-items-baseline">
                <div className="flex-fill">
                    <h2 className="welcome-h4">
                        Enterprise Kit
                    </h2>
                </div>
                <div>
                    <h2 className="h2 material-icons m-0">apartment</h2>
                </div>
            </div>
            <form className="form-horizontal" onSubmit={onSubmit}>
                <TypedLoading state={state} render={(data: Data) => {
                    return (
                        <LoginFormBody data={data} onChange={onChange} />
                    )
                }} />
            </form>
        </div>
    );
}

interface LoginFormBodyProps {
    data: Data;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function LoginFormBody({ data, onChange }: LoginFormBodyProps) {
    const { credentials } = data;
    return (
        <div>
            <div>
                <div className="form-group">
                    <label className="control-label">
                        E-Mail
                    </label>
                    <div className="controls">
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            value={credentials.email}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label">
                        Passwort
                    </label>
                    <div className="controls">
                        <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={credentials.password}
                            onChange={onChange}
                        />
                    </div>
                </div>
                {data.error && (<div>Fehler beim Login: {data.error}</div>)}
            </div>
            <div className="flex-column pt-3 text-center">
                <button type="submit" className="btn btn-block btn-outline-success m-0">
                    Einloggen
                </button>
                <div className="pt-5">
                    <Link to="/passwordRestore">
                        Passwort vergessen?
                    </Link>
                    <span className="m-1">oder</span>
                    <Link to="/register">
                        Registrieren
                    </Link>
                </div>
            </div>
        </div >
    )
}