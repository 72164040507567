import React from 'react';

export interface Author {
    surname: string;
    name: string;
    email: string;
}

export interface AuthorProps {
    author: Author;
}

export function Author({ author }: AuthorProps) {
    let str = null;
    if (author.surname && author.name) {
        str = `${author.surname} ${author.name}`;
    } else {
        str = author.email;
    }
    return <>{str}</>;
}