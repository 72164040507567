import React from 'react';
import { XistFile } from '../api';

export interface ImagePreviewProps {
    file: XistFile
}

export function ImagePreview({ file }: ImagePreviewProps) {
    return (
        <img src={file.url} style={{ maxWidth: "100%" }} />
    )
}