import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { universalLanguageDetect } from '@unly/universal-language-detector';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    de: {
        translation: {
            "Projekte": "Projekte",
            "Logout": "Abmelden",
            "archived": "Das Projekt wurde am {{d}} archiviert. Zum Reaktivieren kontaktieren Sie bitte den Support.",
            "log_list_changed": "{{author}} hat im Project {{project_name}} den Xist ",
            "dashboard_type_erstellt": "erstellt",
            "dashboard_type_aktualisiert": "aktualisiert",
            "dashboard_type_kommentiert": "kommentiert"
        }
    },
    en: {
        translation: {
            "erstellt": "created",
            "speichern": "Save",
            "abbrechen": "Cancel",
            "gelesen": "read",
            "log_list_changed": "{{author}} has {{type}} the xist ",
            "dashboard_type_erstellt": "",
            "dashboard_type_aktualisiert": "",
            "dashboard_type_kommentiert": "commented",
            "aktualisiert": "updated",
            "archived": "The project was archived on {{d}}. For reactivation please contact support.",
            "Projekte": "Projects",
            "Welcome to React": "Welcome to React and react-i18next",
            "Übersicht": "Dashboard",
            "Neues Projekt": "New Project",
            "Aktiv": "Active",
            "Inaktiv": "Inactive",
            "Logout": "Log out",
            "Aus Vorlage": "From template",
            "Projektname": "Project name",
            "Ohne": "without",
            "Name des neuen Projekts": "Name of the new project",
            "xist Ordner erstellen": "Create new xist folder",
            "Erledigte anzeigen": "Show completed",
            "xist erstellen": "Create xist",
            "Neuer Xist": "New xist",
            "Neue Xistsammlung": "New xist collection",
            "Abbrechen": "cancel",
            "Erstellen": "create",
            "Alle Xists": "All xists",
            "Meine Xists": "My xists",
            "Verlauf": "Stream",
            "Keine Xists in dieser Liste": "No xists in this list",
            "keine ungelesenen Aktivitäten": "No unread activities",
            "Fälligkeit aufsteigend": "Due date ascending",
            "Fälligkeit absteigend": "Due date descending",
            "Status aufsteigend": "Status ascending",
            "Status absteigend": "Status descending",
            "An Beta Programm teilnehmen": "Participate in beta program",
            "Speichern": "save",
            "Neues Passwort": "New password",
            "Neues Passwort wiederholen": "Repeat new password",
            "E-Mail": "E-mail",
            "Name": "Name",
            "Vorname": "Surname",
            "Straße": "Street",
            "Postleitzahl": "Zip",
            "Stadt": "City",
            "Mobil": "Mobile",
            "Telefon": "Phone",
            "Telefax": "Fax",
            "Mein Profil": "My profile",
            "Persönliche Daten": "Personal data",
            "Zugangsdaten": "Login data",
            "Kommentar ausgeblendet": "Comment hidden",
            "Kommentar gelöscht": "Comment deleted",
            "Wollen Sie den Kommentar wirklich löschen?": "Do you really want to delete this comment?",
            "Noch keine Kommentare vorhanden": "No comments yet.",
            "Kommentare": "Comments",
            "Markierte": "Marked",
            "Fälligkeit": "Due date",
            "Status": "Status",
            "Zugewiesen an": "Assigned to",
            "Erstellt durch": "Created by",
            "Stand": "Version",
            "Änderungen verwerfen?": "Discard changed",
            "offen": "Open",
            "in Bearbeitung": "In progress",
            "fertiggestellt": "Completed",
            "Dateien": "Files",
            "Datei auswählen": "Pick file",
            "Dateien hier ablegen": "Drop files here",
            "oder": "or",
            "Änderungen speichern": "Save changes",
            "Achtung! Sie bearbeiten gerade nicht die aktuellste Version! M&ouml;gliche &Auml;nderungen in neueren Versionen werden ggf. &uuml;berschrieben!": "Attention! Your're currently not editing the latest version. Changes in newer versions might get overwritten!",
            "Beschreibung": "Description",
            "Archiviert am": "Archived on",
            "Projektverwaltung": "Project administration",
            "Projektübersicht": "Project dashboard",
            "Projekt archivieren": "Archive project",
            "Administrator": "Administrator",
            "Benutzer": "User",
            "Übernehmen": "Apply",
            "Das ist keine E-Mail Adresse": "Invalid address",
            "Hinzufügen": "Add user",
            "Kontakt": "Contact",
            "Rolle im Projekt": "Role",
            "Sortieren": "Sort by",
            "In neuem Tab öffnen": "Open in new tab",
            "Umbenennen": "Rename",
            "Löschen": "Delete",
            "Fällig": "Due"
        }
    }
};

const lang = universalLanguageDetect({
    supportedLanguages: ['en', 'de'], // Whitelist of supported languages, will be used to filter out languages that aren't supported
    fallbackLanguage: 'en', // Fallback language in case the user's language cannot be resolved
   //acceptLanguageHeader: get(req, 'headers.accept-language'), // Optional - Accept-language header will be used when resolving the language on the server side
    //serverCookies: cookies, // Optional - Cookie "i18next" takes precedence over navigator configuration (ex: "i18next: fr"), will only be used on the server side
    errorHandler: (error) => { // Optional - Use you own logger here, Sentry, etc.
      console.log('Custom error handler:');
      console.error(error);
    },
  });
  console.debug("lang is ", lang)

i18n
.use(I18nextBrowserLanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            order: ['navigator',]
        },
        resources,
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
      
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

    i18n.sw
export default i18n;