import React, { useEffect, useMemo, useState } from "react";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { withKeyAndJsonHeaders } from "../api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
//import { useTranslation } from "react-i18next";

export interface DashboardXist {
    id: number;
    xist_id: number;
    project_id: number;
    project_name: string;
    name: string;
    status: number;
    deadline: string;
    show_project_name: boolean;
}

interface DashboardXistStatusProps {
    xist: DashboardXist;
}

function DashboardXistStatus({ xist }: DashboardXistStatusProps): React.JSX.Element {
    const [status, setStatus] = useState<TypedLoadingState<number, void>>({ state: 'success', data: xist.status });
    useEffect(() => {
        const abortController = new AbortController();
        console.debug("running hook for dashboardentry")
        if (status.state === 'loading') {
            fetch(`/api/v1/project/${xist.project_id}/tasks/${xist.xist_id}/status`, withKeyAndJsonHeaders({ method: 'PUT', body: JSON.stringify({ id: xist.xist_id, status: status.data }) })).then(
                async _response => {
                    if (status.state === "loading")
                        setStatus({ state: 'success', data: status.data });
                }
            )
        }
        return () => {
            abortController.abort();
        }
    }, [status]);
    const onStatusChange = (_e: React.MouseEvent<HTMLButtonElement>, status: number) => {
        setStatus({ state: "loading", data: status });
    }
    return (
        <TypedLoading state={status} render={(status) => {
            return (
                <div className="dropdown">
                    <button className="btn btn-sm dropdown-toggle p-0 m-0" type="button"
                        id={`dropdownMenuButton-${xist.xist_id}`}
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className={`xist-status xist-status-${status}`} />
                    </button>
                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${xist.xist_id}`}>
                        <button className="dropdown-item" type="button" onClick={(e: any) => onStatusChange(e, 0)}>
                            <span className="xist-status xist-status-0" />offen
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e: any) => onStatusChange(e, 1)}>
                            <span className="xist-status xist-status-1" />in Bearbeitung
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e: any) => onStatusChange(e, 2)}>
                            <span className="xist-status xist-status-2" />fertiggestellt
                        </button>
                    </div>
                </div>);
        }} />
    )
}

interface DashboardXistListProps {
    caption: string;
    data: DashboardXist[];
    onClickOpenXist: (xist: DashboardXist) => void;
    reload: () => void;
    tooltip: string;
    projectId?: number;
}
export function DashboardXistList({ caption, data, tooltip, projectId }: DashboardXistListProps) {
    //const [t, i18n] = useTranslation();
    const [sorting, setSorting] = useState<SortingState>([]);
    let list: DashboardXist[] = data;

    const columns = useMemo<any>(
        () => {
            let cols = [
                {
                    header: 'Name',
                    accessorKey: 'name',
                    cell: (row: any) => {
                        const { project_id, xist_id, name } = row.row.original;
                        return (
                            <Link to={`/project/${project_id}/xist/${xist_id}`}>{name}</Link>
                        )
                    }
                },
                {
                    header: 'Status',
                    cell: (row: any) => {
                        const xist = row.row.original;
                        return (
                            <DashboardXistStatus xist={xist} />
                        )
                    },
                    accessorKey: 'status'
                },
                {
                    header: 'Fälligkeit',
                    cell: (cell: any) => <Moment locale="de" fromNow>{cell.renderValue()}</Moment>,
                    accessorKey: 'deadline'
                }
            ];
            if (projectId === undefined) {
                cols.unshift({
                    header: 'Projekt',
                    cell: (row: any) => {
                        const { project_id, project_name } = row.row.original;
                        return (
                            <Link to={`/project/${project_id}/xist`}>{project_name}</Link>
                        )
                    },
                    accessorKey: 'project_name'
                });
            }
            return cols;
        }, []
    );

    const table = useReactTable({
        columns,
        data: list,
        state: {
            sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        debugTable: true
    });

    return (
        <div className="col-md mh-100 overflow-auto">
            <Row>
                <Col>
                    <h3 data-toggle="tooltip" data-placement="bottom"
                        title={tooltip}>{caption}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className="table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: ' ⌃',
                                                        desc: ' ⌄',
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div >
    )
}