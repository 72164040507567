import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import { Project, withKeyAndJsonHeaders } from "../../../api";
import { TypedLoading, TypedLoadingState } from "../../../utils/Loading";

type onCancelCallback = () => void;
type onSuccessCallback = (issue_id: number, issue_version_id: number) => void;
type onChangeCallback = () => void;


export interface XistPost {
    name: string,
    parent_id: number | null,
    directory: boolean
}

interface CreateXistProps {
    parent_id: number | null;
    directory: boolean;
    onCancel: onCancelCallback;
    onSuccess: onSuccessCallback;
    onChange: onChangeCallback;
    project: Project;
}

export function CreateXist(props: CreateXistProps) {
    let parent_id = props.parent_id;
    parent_id = parent_id == 0 ? null : parent_id;
    const [error, setError] = React.useState<string | null>(null);
    const [state, setState] = useState<TypedLoadingState<any, any>>({ state: "initial", data: { name: "" } });
    const abortController = new AbortController();

    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ state: "initial", data: { name: e.target.value } });
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (state.state !== "initial") {
            return;
        }
        if (state.data.name.length === 0) {
            setError('Xistname darf nicht leer sein!');
            return;
        }
        setState({ state: 'loading', message: "Xist wird erstellt...", data: state.data });
        let xist: XistPost = {
            name: state.data.name,
            parent_id: parent_id,
            directory: props.directory
        };

        const response = await fetch(`/api/v1/project/${props.project.id}/tasks`, withKeyAndJsonHeaders({
            method: 'POST',
            signal: abortController.signal,
            body: JSON.stringify(xist)
        }));

        if (response.body) {
            //const decoder = new TextDecoder();
            const streamReader = response.body.pipeThrough(new TextDecoderStream());
            const reader = streamReader.getReader();
            let buffer = '';
            try {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    buffer += value;
                    const lines = buffer.split("\n");
                    buffer = lines.pop() || '';

                    lines.forEach((line) => {
                        console.debug("line is", line)
                        try {
                            const jsonObject = JSON.parse(line);
                            console.debug("parsed is", line);
                            switch (jsonObject.type) {
                                case "Completed":
                                    console.debug("completed creation")
                                    let xist = jsonObject.data;
                                    props.onSuccess(xist.task_id, xist.id);
                                    break;
                                case "SendingMail":
                                    setState({ state: "loading", message: "Xist erstellt, Versende Mails...", data: state.data });

                            }
                        } catch (error) {
                            console.debug("babuba", error);
                        }
                    });
                }
            } finally {
                reader.releaseLock();
            }
        }

    }

    return (
        <TypedLoading state={state} render={(data) => {
            return (
                <div className="col">
                    <form onSubmit={onSubmit}>
                        <h2>{(props.directory == true) ? "Neuer Ordner" : "Neuer Issue"}</h2>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control form-control-sm" name="xistname" value={data.name} onChange={onChange} />
                        </div>
                        <div className="form-group float-end">
                            {error ? (
                                <div className="alert alert-danger">
                                    {error}
                                </div>) : (
                                <React.Fragment>
                                    <Link to=".." className="btn btn-danger">Abbrechen</Link>
                                    <button className="btn btn-success" type="submit">Erstellen</button>
                                </React.Fragment>
                            )
                            }
                        </div>
                    </form>
                </div>
            );
        }} />
    )
}