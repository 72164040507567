import React from "react";
import RTCContactCard from "./RTCPhoneContactCard";

interface RTCPhoneContactsProps {
    contacts: any[] | null;
    addToCall?: any;
    call: any;
}

export default function RTCPhoneContacts(props: RTCPhoneContactsProps) {
    const {contacts, addToCall} = props;
    if (contacts) {
        return (
            <React.Fragment>
                {contacts.map((contact: any) => <RTCContactCard key={contact.id} contact={contact} call={props.call} addToCall={addToCall}/>)}
            </React.Fragment>
        )
    } else {
        return <p>Keine Kontakte vorhanden.</p>
    }

}
