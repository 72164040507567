import React, { useState } from 'react';
import { XistFile } from '../api';
import { Document, Page } from 'react-pdf';

import "react-pdf/dist/esm/Page/TextLayer.css";

export interface PDFPreviewProps {
    file: XistFile
}

export function PDFPreview({ file }: PDFPreviewProps) {
    const [pageNumber, _setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = (e: any) => {
        setNumPages(e.numPages);
    }

    return (
        <div className='mw-100'>
            <p>
                Seite {pageNumber} von {numPages}
                {pageNumber > 1 && <button type='button' className='btn btn-link' onClick={() => { _setPageNumber(pageNumber - 1) }}>Vorherige Seite</button>}
                {numPages && (pageNumber < numPages) && <button className='btn btn-link' type='button' onClick={() => { _setPageNumber(pageNumber + 1) }}>Nächste Seite</button>}
            </p>
            <Document options={{
                cMapUrl: 'cmaps/',
                cMapPacked: true,
            }}
                file={file.url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} renderTextLayer={false} />
            </Document>
        </div>
    )
}