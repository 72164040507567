import React, { useEffect, useRef, useState } from "react";
import { Project, withKeyAndJsonHeaders } from "../../../api";
import { MyXist } from "../XistList";
import AddCloudDocument from "./AddCloudDocument";

interface CloudDocument {
    id: number;
    name: string;
    link: string;
}

interface CloudDocumentsProps {
    project: Project;
    xist: MyXist;
}

export function CloudDocuments({ project, xist }: CloudDocumentsProps) {
    const [cloudDocuments, setCloudDocuments] = useState<CloudDocument[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const abortController = useRef<AbortController>(new AbortController());

    useEffect(() => {
        refresh();

        return () => {
            abortController.current.abort();
        };
    }, []);

    const refresh = () => {
        abortController.current = new AbortController();
        fetch(
            `/api/v1/project/${project.id}/tasks/${xist.id}/cloud_documents`,
            withKeyAndJsonHeaders({
                signal: abortController.current.signal,
            }),
        )
            .then(async (response) => {
                const cloudDocuments = await response.json();
                setCloudDocuments(cloudDocuments.cloud_documents);
            })
            .catch(() => {});
    };

    const delete_document = (document: CloudDocument) => {
        abortController.current = new AbortController();
        fetch(
            `/api/v1/project/${project.id}/tasks/${xist.id}/cloud_document/${document.id}`,
            withKeyAndJsonHeaders({
                method: "DELETE",
                signal: abortController.current.signal,
            }),
        ).then(() => {
            refresh();
        });
    };

    return (
        <>
            {modalVisible && (
                <AddCloudDocument
                    project={project}
                    xist={xist}
                    onSuccess={() => {
                        setModalVisible(false);
                        refresh();
                    }}
                />
            )}
            <span>CloudDocuments</span>
            <div style={{ flex: 1, flexDirection: "column" }}>
                {cloudDocuments.map((document) => (
                    <div
                        key={document.id}
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                        }}
                    >
                        <a
                            style={{ flexGrow: 1 }}
                            target="_blank"
                            href={document.link}
                        >
                            {document.name}
                        </a>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                delete_document(document);
                            }}
                        >
                            <span className="material-icons">delete</span>
                        </a>
                    </div>
                ))}
                <button onClick={() => setModalVisible(true)}>
                    Hinzufügen
                </button>
            </div>
        </>
    );
}
