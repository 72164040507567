import React, { useState } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import Dropzone from "react-dropzone";
import { withKeyAndJsonHeaders } from '../../../api';
import { Link } from 'react-router-dom';
import { XistPost } from './CreateXist';

interface CreateXistWithFilesProps {
    project_id: number;
    parent_id: number | null;
}

interface NewXist {
    id: number;
    name: string;
}

export function CreateXistWithFiles({ project_id, parent_id }: CreateXistWithFilesProps) {
    const [new_xist, setNewXist] = useState<NewXist | null>(null);
    const [loadingProgress, setLoadingProgress] = useState<number | null>(null);
    const onDrop = async (files: Array<File>) => {
        setNewXist(null);
        const first = files.at(0);
        if (first === undefined) {
            console.debug("error, no files provided!");
            return;
        }

        console.debug("xistname ", first.name, parent_id)
        const xist_parent_id: number | null = parent_id === 0 ? null : parent_id;

        let xist: XistPost = {
            name: first.name,
            parent_id: xist_parent_id,
            directory: false
        };
        console.debug("post data is ", xist)
        const response = await axios.post(`/api/v1/project/${project_id}/tasks`, xist, withKeyAndJsonHeaders({
            onUploadProgress: function (progressEvent: any) {
                console.debug(progressEvent);
            },
        }));

        const new_xist = response.data;

        let { id: xist_id } = new_xist;
        const formData = new FormData();
        files.forEach((file: File) => formData.append("file", file))

        const query = '?task_id=' + xist_id;
        let headers = withKeyAndJsonHeaders();
        let key = headers.headers.key;
        await axios.post('/api/v1/project/' + project_id + '/files' + query, formData, {
            headers: {
                "key": key,
            },
            onUploadProgress: function (e: AxiosProgressEvent) {
                if (e.progress) {
                    setLoadingProgress(e.progress * 100);
                    console.debug(e)
                }
            },
        });
        console.debug("after post")
        setNewXist({ id: xist_id, name: first.name });
        setLoadingProgress(null);
        /*onSuccess({
            id: new_xist.id,
            xist_id: new_xist.xist_id,
            changed: new_xist.xist_id
        });*/
    }
    return (
        <>
            {new_xist && (<Link to={`/project/${project_id}/xist/${new_xist.id}/createXist`}>{new_xist.name}</Link>)}
            {loadingProgress && <span>{loadingProgress} &percnt;</span>}
            <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })} className="text-center p-3 border">
                        <input {...getInputProps()} />
                        <p>Dateien hier ablegen um einen neuen Xist zu erstellen <br />oder</p>
                        <button type="button">
                            Datei auswählen
                        </button>
                    </div>
                )}
            </Dropzone>
        </>
    )
}