
import React, { useReducer } from 'react';
import { Profile } from './ProfilePresenter';

export interface ContactDetailsProps {
    profile: Profile;
    saveProfile: (profile: Profile) => void;
}


enum ProfileKind {
    SURNAME = 'surname',
    NAME = 'name',
    STREET = 'street',
    ZIP = 'zip',
    CITY = 'city',
    MOBILE = 'mobile',
    PHONE = 'phone',
    FAX = 'fax'
};

interface ProfileAction {
    type: ProfileKind;
    value: string;
}

function profileReducer(state: Profile, action: ProfileAction) {
    const { type, value } = action;
    switch (type) {
        case ProfileKind.SURNAME:
            return {
                ...state,
                surname: value
            };
        case ProfileKind.NAME:
            return {
                ...state,
                name: value
            };
        case ProfileKind.STREET:
            return {
                ...state,
                street: value
            };
        case ProfileKind.ZIP:
            return {
                ...state,
                zip: value
            };
        case ProfileKind.CITY:
            return {
                ...state,
                city: value
            };
        case ProfileKind.PHONE:
            return {
                ...state,
                phone: value
            };
        case ProfileKind.MOBILE:
            return {
                ...state,
                mobile: value
            };
        case ProfileKind.FAX:
            return {
                ...state,
                fax: value
            };
        default:
            return state;
    }
}
export function ContactDetails({ profile: initialProfile, saveProfile }: ContactDetailsProps) {
    const [profile, dispatch] = useReducer(profileReducer, initialProfile);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        saveProfile(profile);
    }

    return (
        <div className="col-12 col-md-6" style={{ height: '100vh' }}>
            <form className="userdata-form form-horizontal position-relative" onSubmit={onSubmit}>
                <legend>Persönliche Daten</legend>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-name">Name</label>
                    <div className="controls">
                        <input id="input-name" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.NAME, value: e.target.value })}
                            type="text" name="name" value={profile.name}></input>
                    </div>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-surname">Vorname</label>
                    <input id="input-surname" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.SURNAME, value: e.target.value })}
                        type="text" name="surname" value={profile.surname}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-street">Straße</label>
                    <input id="input-street" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.STREET, value: e.target.value })}
                        type="text" name="street" value={profile.street}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-zip">Postleitzahl</label>
                    <input id="input-zip" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.ZIP, value: e.target.value })}
                        type="text" name="zip" value={profile.zip}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-city">Stadt</label>
                    <input id="input-city" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.CITY, value: e.target.value })}
                        type="text" name="city" value={profile.city}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-mobile">Mobil</label>
                    <input id="input-mobile" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.MOBILE, value: e.target.value })}
                        type="text" name="mobile" value={profile.mobile}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-phone">Telefon</label>
                    <input id="input-phone" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.PHONE, value: e.target.value })}
                        type="text" name="phone" value={profile.phone}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-fax">Telefax</label>
                    <input id="input-fax" className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ProfileKind.FAX, value: e.target.value })}
                        type="text" name="fax" value={profile.fax}></input>
                </div>
                <div className='mb-3'>
                    <input type="submit" className="btn btn-success save" value={'Speichern'} />
                </div>
            </form>
        </div>
    );
}