import React, { useState } from 'react';

import { XistCommentForm } from "../XistCommentForm";
import { XistComment } from "./XistComment";
//import { useTranslation } from 'react-i18next';

interface XistCommentsProps {
    comments_: any;
    xist: any;
    projectId: number;
    collapsed?: boolean;
}

export function XistComments(props: XistCommentsProps) {
    //const [t, i18n] = useTranslation();
    const [collapsed, setCollapsed] = useState(props.collapsed);
    let comments = props.comments_;
    let marked: any[] = [];
    if (comments) {
        marked = comments.filter((comment: any) => {
            return comment.marked === true
        }).map((comment: any) =>
            <XistComment key={comment.id} comment={comment} projectId={props.projectId} />
        );
        comments = comments.map((comment: any) =>
            <XistComment key={comment.id} comment={comment} projectId={props.projectId} />
        );
    } else {
        comments = <p>Noch keine Kommentare vorhanden</p>;
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    <h4>
                        Kommentare
                        <span className='material-icons' onClick={() => setCollapsed(!collapsed)}>{collapsed ? "expand_more" : "expand_less"}</span>
                    </h4>
                    {!collapsed && (
                        <XistCommentForm xistId={props.xist.id} projectId={props.projectId} />
                    )}
                </div>
            </div>
            {!collapsed && (
                <>
                    <div className="row">
                        {marked.length > 0 && (
                            <div className="col">
                                <h5>'Markierte:</h5>
                                {marked}
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col">
                            <h5>Kommentare:</h5>
                            {comments}
                        </div>
                    </div>
                </>)}
        </React.Fragment>
    );
}