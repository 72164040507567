import React from "react";

interface User {
    id: number;
    surname: string;
    name: string;
    email: string;
}

interface RTCContactCardProps {
    call: (id: number, args?: any) => void;
    contact: User;
    addToCall: boolean;
}

export default function RTCContactCard({call, contact, addToCall}: RTCContactCardProps) {
    return (
        <div key={contact.id} className="contact m-2">
            <div className="card">
                <div className="card-body">
                    <p className="card-text d-flex flex-row">
                        <span className="flex-grow-1 text-left">
                            <span className="card-title h5 d-block">{contact.surname} {contact.name}</span>
                            {contact.email && <span>{contact.email}</span>}
                        </span>
                        <span className="d-flex flex-column">
                            <button className="btn float-end"
                                    onClick={async () => {
                                        const videoStreams = await navigator.mediaDevices.getDisplayMedia({audio: true, video: { width: {"max": 1920}, height: {"max": 1080}}});
                                        console.debug("videostreams are", videoStreams);
                                        call(contact.id, {audio: true, camera: false, desktop: false})}
                                    }>
                                <i className="material-icons" style={
                                    {
                                        color: '#green',
                                        display: 'inline-flex',
                                        verticalAlign: 'middle'
                                    }}>
                                    phone
                                </i>
                            </button>
                            <button className="btn float-end"
                                    onClick={() => {
                                        if (addToCall === true) {
                                            call(contact.id);
                                            return;
                                        }
                                        const stream = new MediaStream();
                                        navigator.mediaDevices.getDisplayMedia({video: true, audio: false}).then(
                                            (video_streams) => {
                                                video_streams.getTracks().forEach(
                                                    (track) => {
                                                        stream.addTrack(track);
                                                    }
                                                );
                                        }).finally(() => {
                                            navigator.mediaDevices.getUserMedia({audio: true}).then(
                                                (audio_streams) => {
                                                    audio_streams.getTracks().forEach((track) => {
                                                        stream.addTrack(track);
                                                });
                                            }).finally(() => {
                                                call(contact.id, stream);
                                            });
                                        })
                                    }}>
                                <i className="material-icons" style={
                                    {
                                        color: '#green',
                                        display: 'inline-flex',
                                        verticalAlign: 'middle'
                                    }
                                }>
                                    tv
                                </i>
                            </button>
                            <button className="btn float-end"
                                onClick={() => {
                                    const stream = new MediaStream();
                                    navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(
                                        (video_streams) => {
                                            video_streams.getTracks().forEach(
                                                (track) => {
                                                    stream.addTrack(track);
                                                }
                                            );
                                    }).finally(() => {
                                        navigator.mediaDevices.getUserMedia({audio: true}).then(
                                            (audio_streams) => {
                                                audio_streams.getTracks().forEach((track) => {
                                                    stream.addTrack(track);
                                                });
                                        }).finally(() => {
                                            call(contact.id, stream);
                                        });
                                    });
                                }}>
                                <i className="material-icons" style={
                                    {
                                        color: '#green',
                                        display: 'inline-flex',
                                        verticalAlign: 'middle'
                                    }}>
                                    videocam
                                </i>
                            </button>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
