/**
 * @author Steffen Kittel
 */
import React, { ChangeEvent, useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';

import { Project, User as ApiUser, withKeyAndJsonHeaders } from '../../api';
import { Loading, LoadingState } from '../../utils/Loading';
import { NewUser } from './NewUser';

interface UserProps {
    user: ApiUser;
    project: Project;
    projectLead: boolean;
}

export function User({ user, project, projectLead }: UserProps) {
    //const [t, i18n] = useTranslation();
    const [_changed, setChanged] = useState<boolean>(false);
    const [role, setRole] = useState<number>(user.role);
    const [loading, setLoading] = useState<LoadingState>(LoadingState.SUCCESS);
    const abortController = new AbortController();

    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, []);

    function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setRole(parseInt(e.target.value));
        //setChanged(user.role !== e.target.role); TODO FIX
        setChanged(true);
    }

    function onSubmit(e: React.FormEvent<HTMLButtonElement>) {
        e.preventDefault();
        setLoading(LoadingState.LOADING);
        const permission = {
            project_id: project.id,
            user_id: user.id,
            role_id: role
        };
        fetch(`/api/v1/project/${permission.project_id}/users`, withKeyAndJsonHeaders({
            method: 'PUT',
            signal: abortController.signal,
            body: JSON.stringify({ role_id: permission.role_id, user_id: permission.user_id })
        })).then(
            () => setLoading(LoadingState.SUCCESS)
        );
    }


    return (
        <tr className="user user-row" data-user-id="user.id">
            <td className="user-cell">
                E-Mail: <a href={`mailto:${user.mail}`}>{user.email}</a><br />
                Name: <br />{user.surname && user.name ?
                    <p>
                        {user.surname} {user.name}
                    </p>
                    :
                    <p className="text-muted">
                        nicht hinterlegt
                    </p>
                }
                Anschrift: <br /> {user.street && user.zip && user.city ?
                    <p>
                        {user.street} <br />
                        {user.zip} {user.city}
                    </p>
                    :
                    <p className="text-muted">
                        nicht hinterlegt
                    </p>
                }
                Tel.: {user.phone}<br />
                Fax: {user.fax && user.fax}<br />
            </td>
            <td className="user-cell">
                <span className="role" data-role-id="user.role ">
                    <Loading state={loading}>
                        <SelectRole inputName='status' onChange={onChange} value={role} options={project.roles} disabled={!projectLead} />
                    </Loading>
                    {!(role == user.role) &&
                        <button className="btn btn-success float-end" onClick={onSubmit}>Übernehmen</button>}
                </span>
            </td>
        </tr>
    );
}

interface UserListProps {
    project: Project;
    isProjectLead: boolean;
    propsPL: boolean;
    readOnly: boolean;
}

type Modes = "list" | "add";

export function UserList({ project, isProjectLead: propsPL, readOnly }: UserListProps) {
    const [mode, setMode] = useState<Modes>('list');
    let isProjectLead = propsPL && !readOnly;

    return (
        <div className="col-12 py-3">
            <h2>Team</h2>
            {mode === 'list' ? (
                <div className="shadow py-3">
                    {!readOnly && isProjectLead &&
                        <button className="btn btn-outline-primar float-end mb-3 mr-3"
                            onClick={() => { setMode('add') }}>Hinzufügen</button>}
                    <UserTable isProjectLead={isProjectLead} project={project} />
                </div>
            ) : (
                <NewUser projectId={project.id} onClickCancel={() => { setMode('list') }} roles={project.roles} />
            )}
        </div>
    );
}

export interface SelectOption {
    id: number;
    name: string;
}

export interface SelectRoleProps {
    options: SelectOption[];
    inputName: string;
    value: number;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
}

export function SelectRole({ disabled, options, value, onChange }: SelectRoleProps) {
    return (
        <select className="form-control form-control-sm" name="role" value={value} onChange={onChange} disabled={disabled}>
            {options.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
        </select>
    )
}

export interface UserTableProps {
    project: Project,
    isProjectLead: boolean;
}

function UserTable({ project, isProjectLead }: UserTableProps) {
    return (
        <table className="table">
            <thead className="table-striped">
                <tr>
                    <th>Kontakt</th>
                    <th>Rolle im Projekt</th>
                </tr>
            </thead>
            <tbody>
                {project.users.sort((a: ApiUser, b: ApiUser) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(
                    (user: ApiUser) => <User key={user.id} project={project} projectLead={isProjectLead} user={user} />
                )}
            </tbody>
        </table>)
}