import React, { useEffect, useState } from 'react';

import { withKeyAndJsonHeaders } from '../../api';
import { Loading, LoadingState } from '../../utils/Loading';
import { Editor } from '../../../ui/components/Editor';

interface XistCommentFormProps {
    projectId: number;
    xistId: number;
}

export function XistCommentForm({ projectId, xistId }: XistCommentFormProps) {
    const [loading, setLoading] = useState<LoadingState>(LoadingState.SUCCESS);
    const [message, setMessage] = useState("");
    const abortController = new AbortController();

    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, []);

    const onChange = (text: string) => {
        setMessage(text);
    }

    const onSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(LoadingState.LOADING);

        fetch(`/api/v1/project/${projectId}/tasks/${xistId}/comments`, withKeyAndJsonHeaders({
            method: 'POST',
            signal: abortController.signal,
            body: JSON.stringify({ comment: message })
        })).then(
            () => {
                setLoading(LoadingState.SUCCESS);
                setMessage("");
            }
        );
    }

    return (
        <Loading state={loading}>
            <div className="d-flex flex-row">
                <Editor onTextChange={onChange} defaultValue={message} className="flex-grow-1 h-100 border" />
                <button className="btn mt-auto mb-0" onClick={onSubmit} type="button"
                    disabled={message.length == 0}>
                    <i className="material-icons align-bottom" style={{ "color": "#3579F7" }}>send</i>
                </button>
            </div>
        </Loading>
    )
}
