import React, { useEffect, useState } from "react";

import { withKeyAndJsonHeaders } from "../api";
import { Link, useMatch, useParams } from "react-router-dom";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";
import classNames from "classnames";

type onChangeQueryCallback = (query: string) => void;
type onClearCallback = () => void;

export interface SearchBarProps extends React.HTMLAttributes<HTMLDivElement> {
    onChangeQuery: onChangeQueryCallback;
    onClear: onClearCallback;
    matchPattern: string;
}

export function SearchBar({ onChangeQuery, className, onClear, matchPattern }: SearchBarProps) {
    const match = useMatch(matchPattern);
    const query = match?.params.query;
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        onChangeQuery(query);
    }
    return (
        <div className={classNames("row, pt-3", className)}>
            <div className="col">
                <div className="form-floating mb-3">
                    <div className="input-group">
                        <input className="form-control" type="text" id="searchbar_global"
                            value={query} onChange={onChange} placeholder="Suche"
                            autoCorrect="off" autoComplete="off" onFocus={onChange} onKeyDown={(e) => { if (e.key == 'Escape') { onClear(); } }} />
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={onClear}>
                            <span className="material-icons">clear</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function SearchResults() {
    const { query: queryEncoded } = useParams();
    if (!queryEncoded) {
        return (
            <p>Kein Suchbegriff angegeben.</p>
        )
    }
    const query = decodeURIComponent(queryEncoded);
    //const [results, setResults] = useState<Array<any> | null>(null);
    const [results, setResults] = useState<TypedLoadingState<SearchResult[] | null, null>>({ state: 'pending' });

    useEffect(() => {
        setResults({ state: 'pending' });
        const abortController = new AbortController();
        fetch('/api/v1/search', withKeyAndJsonHeaders({
            method: 'POST',
            signal: abortController.signal,
            body: JSON.stringify({ query: query })
        })).then(
            async response => {
                const results = await response.json();
                if (results.query == query) {
                    setResults({ state: 'success', data: results.result });
                }
            }
        ).catch(() => { })
        return () => {
            abortController.abort();
        }
    }, [query]);

    return (
        <TypedLoading key={query} state={results} render={(results) => {
            return (
                <div className="row">
                    <div className="col">
                        <div className="search-results mt-3">
                            {results !== null && results.length > 0 ?
                                <SearchResultsList query={query} results={results} />
                                : <p>Leider keine Suchergebnisse für "{query}"</p>
                            }
                        </div>
                    </div>
                </div>);
        }} />
    );
}

interface SearchResultTask {
    task: {
        id: number;
        xist_id: number;
        name: string,
        project_name: string,
        project_closed?: Date,
        project_id: number,
        description: string,
    }
}

interface SearchResultUser {
    user: {
        name: string,
        surname: string
    }
}

type SearchResult = SearchResultTask | SearchResultUser;

interface SearchResultsListProps {
    query: string;
    results: SearchResult[];
}
function SearchResultsList({ query, results }: SearchResultsListProps) {
    let content = results.map(
        (elem: SearchResult) => {
            if ('task' in elem) {
                const task = elem.task;
                return (
                    <Link key={`t-${task.id}`}
                        to={`/project/${task.project_id}/xist/${task.xist_id}`}>
                        <div className="dropdown-item" key={task.id}>
                            <span className="d-block small text-muted">
                                {task.project_closed !== null && "Archiv: "} {task.project_name}
                            </span>
                            <span className={classNames({ "text-muted": task.project_closed !== null })}>
                                {task.name}
                            </span>
                        </div>
                    </Link>
                )
            } else if ('user' in elem) {
                const user = elem.user;
                return (
                    <li key={`user-${user.surname}${user.name}`}>
                        {user.surname} {user.name}
                    </li>
                )
            } else {
                return false;
            }
        });
    return (
        <>
            <p>Suchergebnisse für "{query}"</p>
            {content}
        </>
    )
}