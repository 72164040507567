import React from "react";

import { LogEntryTaskComment } from "../DashboardLogList";
import { Author } from "./Common";
import { Link } from "react-router-dom";

export interface LogListItemCommentProps {
    projectId?: number;
    log: LogEntryTaskComment;
}

export function LogListItemComment({ log, projectId }: LogListItemCommentProps) {
    const { author, task_id, task_name, comment_text } = log;
    return (
        <div className="card-text">
            <Author author={author} />{" "} hat <Link to={`/project/${projectId}/xist/${task_id}`}>{task_name}</Link> kommentiert:
            <blockquote className="blockquote">
                <span dangerouslySetInnerHTML={{ __html: comment_text }}></span>
            </blockquote>
        </div>
    );
}