import React, { useState } from "react";
//import NavbarIconWhite from "../../../assets/img/logo.png";
const NavbarIconWhite = "navbar_icon_white";
import {Link} from "react-router-dom";

export enum STATES {
    PASSWORD_SENT = 0,
    INPUT_E_MAIL = 1
}

export function PasswordRestore() {
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>();
    const [state, setState] = useState(STATES.INPUT_E_MAIL);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        let searchParams = new URLSearchParams();
        searchParams.append("email", email);
        fetch(`/api/v1/pwreset?${searchParams.toString()}`).then(
            async response => {
                const {message} = await response.json();
                if (message == "ok") {
                    setMessage("Neues Passwort versendet, bitte prüfen Sie Ihr E-Mail Postfach.");
                    setState(STATES.PASSWORD_SENT);
                } else {
                    setMessage("Fehler, bitte nochmals versuchen");
                }
            });
    }

    if (state == STATES.PASSWORD_SENT) {
        return (
            <div className="modal-dialog shadow-sm" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="welcome-h4 my-auto">Passwort versendet</h2><img
                        className="pull-right welcome-logo border border-dark" src={NavbarIconWhite}
                        style={{width: '48px', height: '48px'}}/>
                    </div>
                    <div className="modal-body">
                        Neues Passwort versendet, bitte E-Mail Posteingang prüfen.
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn btn-primary">Zum Login</Link>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <form className="form-horizontal" onSubmit={onSubmit}>
            <div className="modal-dialog shadow-sm" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="welcome-h4 my-auto">Neues Passwort anfordern</h2><img
                        className="pull-right welcome-logo border border-dark" src={NavbarIconWhite}
                        style={{width: '48px', height: '48px'}}/>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="control-label">E-Mail</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="email" name="email"
                                        onChange={onChange} value={email}/>
                            </div>
                            {message}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn btn-danger">Abbrechen</Link>
                        <button type="submit" className="btn btn-success">Neues Passwort zusenden</button>
                    </div>
                </div>
            </div>
        </form>
    )
}