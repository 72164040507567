import React, { createRef, useEffect, useRef, useState } from "react";
//import queryString from "query-string";

import { withKeyAndJsonHeaders } from '../api';
import { connection } from '../api';
//import incomingCallSound from '../../incoming_call.m4a';
const incomingCallSound = "none";
import RTCPhoneContacts from "./RTCPhoneContacts";
import { useNavigate } from "react-router-dom";

const configuration: RTCConfiguration = {
    "iceServers": [{ "urls": ["turn:xistics.de:3478"], "username": "xistics", "credential": "xistics" }],
    "iceTransportPolicy": "relay",
    "iceCandidatePoolSize": 0
};

interface RTCProps {
    initialOtherSid?: string
}
interface OfferData {
    from_user: any;
    to_user: any;
    offer: any;
}

export function RTC({ initialOtherSid }: RTCProps) {
    const [otherSid, setOtherSid] = useState(initialOtherSid);
    console.debug("match is ", otherSid)
    const navigate = useNavigate();

    const bell = createRef<HTMLAudioElement>();
    const remoteAudioStream = createRef<HTMLAudioElement>();
    const remoteVideoStream = createRef<HTMLVideoElement>();

    const [userId, _setUserId] = useState(localStorage.getItem('user_id'));

    const stream = useRef<MediaStream | null>(null);

    const [guestEMail, setGuestEmail] = useState("");
    const [callActive, setCallActive] = useState(otherSid ? true : false);
    const [contacts, setContacts] = useState(null);
    const [gettingCalled, setGettingCalled] = useState(false);
    const [offerData, setOfferData] = useState<OfferData | null>(null);
    const [videoStreamer, setVideoStreamer] = useState(false);
    const localRTCPeerConnection = useRef<RTCPeerConnection | null>(null);
    const iceCandidatesCache = useRef<RTCIceCandidate[]>([]);
    const [guestList, setGuestList] = useState();
    const [callingGuest, setCallingGuest] = useState(false);
    const [callingGuestMessage, setCallingGuestMessage] = useState<String | null>(null);

    const onGuestList = (guest_list: any) => {
        setGuestList(guest_list);
        setCallingGuest(false);
    }

    const onIceCandidate = (candidate: any) => {
        console.debug("onIceCandidate")
        const rtcIceCandidate = new RTCIceCandidate(candidate.candidate);
        if (localRTCPeerConnection.current === null || localRTCPeerConnection.current.remoteDescription === null) {
            iceCandidatesCache.current.push(rtcIceCandidate);
            return;
        }
        localRTCPeerConnection.current.addIceCandidate(rtcIceCandidate).catch((err) => {
            console.debug("error adding ice candidate", rtcIceCandidate, err);
        }
        );
    }

    const onOtherSid = (otherSid: string) => {
        setOtherSid(otherSid);
    }

    const onOffer = (data: any) => {
        setOfferData(data);
        setCallActive(true);
        setGettingCalled(false);
        answer();
    }

    const onAnswerHandler = (data: any) => {
        // Get reply from callee
        console.debug("onAnswerHandler, got data", data)
        const { answer } = data;
        console.debug("start of answer ----");
        console.debug("got answer data", data);
        console.debug("pc before remote description is ", localRTCPeerConnection.current)
        if (localRTCPeerConnection.current !== null) {
            localRTCPeerConnection.current.setRemoteDescription(answer).then(() => {
                console.debug("pc after answer is", localRTCPeerConnection.current);
                setGettingCalled(false);
                setCallActive(true);
                console.debug("successfully set remote description")
            }).catch((error) => {
                console.debug("error setting remote description", error);
            });
        } else {
            console.debug("localRTCPeerConnection is null!");
        }
        console.debug("end of answer ---");
    }

    const onChangeGuestEMail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGuestEmail(e.target.value);
    }

    const callGuest = () => {
        fetch('/api/v1/rtc/inviteGuest', withKeyAndJsonHeaders({
            method: 'POST',
            body: JSON.stringify({ to: guestEMail })
        })).then(
            () => console.debug("invitation sent")
        );
        setCallingGuest(true);
        setCallingGuestMessage('Einladung versendet, warte auf Antwort.'); // TODO: Implement!!!

        setTimeout(() => {
            setCallingGuest(false);
            setCallingGuestMessage("Leider hat die Gegenseite nicht geantwortet");
        }, 60 * 60 * 1000);
    }

    const acceptCall = async () => {
        console.debug("------ acceptCall()");
        console.debug("accepting call", otherSid);
        setGettingCalled(false)
        if (bell.current) {
            bell.current.pause();
        }
        // HERE COMES THE GETTING CALLED STUFF HIN getting Media etc.
        try {
            const s = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            stream.current = s;
        } catch (error) {
            console.debug("error accepting call", error);
        }
        connection.sock().emit('accept', otherSid);
        offer("answer", otherSid);
    }

    const answer = () => {
        console.debug("------ answer()");
        if (offerData !== null) {


            const { from_user, to_user, offer } = offerData;
            console.debug(from_user, "wants to call");
            setOtherSid(to_user);

            /*const mediaConstraints = {
                audio: false,
                video: true
            };*/
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((audiostream) => {
                stream.current = audiostream;
            }).finally(() => {
                //connection.sock().emit('call', calleeId); ToDo: required?
                offer("offer", from_user);
            });
        }
        // ToDo: Implement else branch
    }

    const add_to_call = async (calleeId: any) => {
        connection.sock().emit('call', calleeId)
    }

    const call = async (calleeId: any, s?: any) => {
        console.debug("----- call()");
        setCallActive(true);
        setVideoStreamer(true);
        stream.current = s;
        console.debug("calling ", calleeId);
        offer("offer");
        connection.sock().emit('call', calleeId);
    }

    const offer = async (role: any, to_user?: any) => {
        console.debug("------ offer()");
        setUpLocalRTCConnection();

        if (stream.current) {
            stream.current.getTracks().forEach((track: any) => {
                if (localRTCPeerConnection.current !== null) {
                    if (stream.current !== null) {
                        localRTCPeerConnection.current.addTrack(track, stream.current);
                    }
                }
            }
            );
        }

        try {
            if (localRTCPeerConnection.current != null) {
                const offer = await localRTCPeerConnection.current.createOffer({ offerToReceiveVideo: true });
                if (iceCandidatesCache.current) {
                    for (let i = 0; i < iceCandidatesCache.current.length; i++) {
                        const ice_candidate = iceCandidatesCache.current.pop();
                        localRTCPeerConnection.current.addIceCandidate(ice_candidate);
                    }
                    iceCandidatesCache.current = [];
                }
                console.debug("offering", offer);
                localRTCPeerConnection.current.setLocalDescription(offer);
                const from_user = localStorage.getItem('user_id');
                if (from_user === null) {
                    return;
                }
                const data = {
                    offer: offer,
                    from_user: parseInt(from_user),
                    to_sid: otherSid,
                    to_user: null
                };
                if (to_user) {
                    data['to_user'] = to_user;
                }
                connection.sock().emit(role, data);
            }
        } catch (error) {
            console.debug("error creating offer and setting description", error);
        }
    }

    const hangUp = () => {
        setGettingCalled(false);
        setCallActive(false);
        setVideoStreamer(false);

        connection.sock().emit("hangUp", { to_user: otherSid });
        if (remoteAudioStream && remoteAudioStream.current !== null) {
            remoteAudioStream.current.srcObject = null;
        }
        /*if (audiostream) {
            audiostream.getTracks().forEach((track) => {
                track.stop();
            });
        }*/
        if (remoteVideoStream && remoteVideoStream.current !== null) {
            remoteVideoStream.current.srcObject = null;
        }
        if (stream.current) {
            stream.current.getTracks().forEach((track: any) => {
                track.stop();
            });
        }
        stream.current = null;
        // setAudioStream(null); // TODO: Check if need to be a state
        if (localRTCPeerConnection.current !== null) {
            localRTCPeerConnection.current.close();
            localRTCPeerConnection.current = null;
        }
        navigate(-1);
    }

    const setUpLocalRTCConnection = () => {
        console.debug("--------- setUpLocalRTCConnection");
        if (localRTCPeerConnection.current) {
            localRTCPeerConnection.current.close();
            localRTCPeerConnection.current = null;
        }
        localRTCPeerConnection.current = new RTCPeerConnection(configuration);
        localRTCPeerConnection.current.onicecandidate = (e) => {
            const { candidate } = e;
            if (!candidate) {
                return;
            }
            console.debug(candidate);
            connection.sock().emit('icecandidate', {
                candidate: candidate
            });
        };
        localRTCPeerConnection.current.oniceconnectionstatechange = (e) => {
            console.debug("oniceconnectionstate", e.target);
        }

        localRTCPeerConnection.current.ontrack = (track) => {
            console.debug("------- got remote stream stuff -------");
            console.debug("streams are", track.streams);
            console.debug("tracks are", track);
            console.debug(track);
            if (track.track.kind == "video") {
                // Only set the remote stream if we're getting called so the caller sees his own stream
                if (!videoStreamer) {
                    if (remoteVideoStream.current) {
                        remoteVideoStream.current.srcObject = track.streams[0];
                        remoteVideoStream.current.autoplay = true;
                        remoteVideoStream.current.muted = false;
                        remoteVideoStream.current.volume = 0
                    } else {
                        console.debug("remoteVideoStream is null");
                    }
                }
            } else {
                if (remoteAudioStream.current) {
                    remoteAudioStream.current.autoplay = true;
                    remoteAudioStream.current.muted = false;
                    remoteAudioStream.current.srcObject = track.streams[0];
                } else {
                    console.debug("remoteAudioStream.current is null");
                }
            }
            console.debug("-------- end remote stream stuff ------");
        };
    }

    useEffect(() => {
        console.debug("component mounted");
        connection.on('answer', onAnswerHandler);
        connection.on('accepted_call', onOtherSid);
        connection.on('offer', onOffer);
        connection.on('icecandidate', onIceCandidate);
        connection.on('guest_list', onGuestList);
        if (userId) {
            fetch('/api/v1/user/contacts', withKeyAndJsonHeaders()).then(
                async response => {
                    const { contacts } = await response.json();
                    setContacts(contacts);
                }
            )
        } else {
            /* ToDo: Fix!
            const {history} = props;
            const queryParams = queryString.parse(history.location.search);
            setContacts([{
                    id: queryParams.id,
                    email: queryParams.email,
                    name: queryParams.name,
                    surname: queryParams.surname
                }
            ]);
            connection.sock().emit('enable_conference', `guest-for-${queryParams.id}`);
            */
        }
        if (otherSid) {
            acceptCall();
        }
        return () => {
            connection.off('answer', onAnswerHandler);
            connection.off('accepted_call', onOtherSid);
            connection.off('offer', onOffer);
            connection.off('icecandidate', onIceCandidate);
            connection.off('guest_list', onGuestList);
        }
    }, [])

    return (
        <div className="row h-100 position-fixed text-center overflow-auto"
            style={{ left: 0, right: 0, top: 0, bottom: 0, backgroundColor: '#fff' }}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <h1>Xistics Screenkonferenzsystem</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col pb-5">
                        Hinweise: für die problemlose Bildschirm- und Audioübertragung verwenden Sie entweder Google
                        Chrome oder Mozilla Firefox<br />
                        Für die Verwendung müssen beide Benutzer Xistics Screenkonferenzsystem in Ihrem Browser
                        geöffnet haben.
                    </div>
                </div>
                <div className="row">
                    <div className="col contacts justify-content-center">
                        <audio ref={bell} src={incomingCallSound} autoPlay={false} loop={true} />
                        {gettingCalled &&
                            <button className="btn btn-success" onClick={acceptCall}>Anruf annehmen</button>}
                    </div>
                </div>
                {!callActive &&
                    <div className="row">
                        {guestList &&
                            <div
                                className="col d-flex flex-row flex-wrap contacts align-content-center justify-content-center">
                                <p className="d-inline-block my-auto">Gast vefügbar. Anruf durchführen?</p>
                                <button className="btn float-end" onClick={() => call(`guest-for-${userId}`)}>
                                    <i className="material-icons"
                                        style={{ color: '#green', display: 'inline-flex', verticalAlign: 'middle' }}>phone</i>
                                </button>
                            </div>
                        }
                    </div>
                }
                {!callActive &&
                    <div className="row">
                        {userId && !callingGuest &&
                            <div
                                className="col-6 mx-auto d-flex flex-row flex-wrap contacts align-content-center justify-content-center">
                                <label htmlFor="guest-email">Gast anrufen</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" onChange={onChangeGuestEMail}
                                        value={guestEMail} placeholder="Gast E-Mail" />
                                    <div className="input-group-append">
                                        <button className="btn float-end" onClick={callGuest}>
                                            <i className="material-icons"
                                                style={{ color: '#green', display: 'inline-flex', verticalAlign: 'middle' }}>
                                                phone
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {callingGuest &&
                            <div
                                className="col-6 mx-auto d-flex flex-row flex-wrap contacts align-content-center justify-content-center">
                                {callingGuestMessage}
                            </div>
                        }
                    </div>
                }
                {!callActive &&
                    <div className="row">
                        <div
                            className="col d-flex flex-row flex-wrap contacts align-content-center justify-content-center">
                            <RTCPhoneContacts contacts={contacts} call={call} />
                        </div>
                    </div>
                }
                {callActive &&
                    <div className="row">
                        <div className="col">
                            <div className="col videostreams">
                                <audio ref={remoteAudioStream} autoPlay={true} />
                                <video className="videostream" ref={remoteVideoStream} autoPlay={true} />
                                <button className="btn btn-danger d-block mx-auto" onClick={hangUp}>
                                    <i className="material-icons"
                                        style={{
                                            color: '#green',
                                            display: 'inline-flex',
                                            verticalAlign: 'middle'
                                        }}>phone</i>
                                    Auflegen
                                </button>
                            </div>
                        </div>
                        <div
                            className="col d-flex flex-row flex-wrap contacts align-content-center justify-content-center">
                            <RTCPhoneContacts contacts={contacts} call={add_to_call} addToCall={true} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}