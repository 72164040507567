/**
 * @author Steffen Kittel
 */
import React from 'react';

import { ProjectSettings } from './ProjectSettings';
import { Project } from '../../api';

interface SettingsTabProps {
    project: Project;
    isProjectLead: boolean;
    readOnly: boolean;
}

export function SettingsTab({ project, isProjectLead, readOnly }: SettingsTabProps) {
    return (
        <div className="row h-100 overflow-auto">
            {isProjectLead == true ?
                <ProjectSettings project={project} isProjectLead={isProjectLead} readOnly={readOnly} />
                :
                <div>Keine Berechtigung.</div>
            }
        </div>
    );
}
