import React, { useEffect, useLayoutEffect, useRef } from 'react'; // ToDo: forwardRef
import Quill from 'quill';

import 'quill/dist/quill.snow.css'

//const Delta = Quill.import('delta');

/*interface Props {
  readOnly: boolean;
  defaultValue: typeof Delta;
  onTextChange: any;
  onSelectionChange: any;
}*/

// Editor is an uncontrolled React component
export const Editor = ({ defaultValue, onTextChange, onSelectionChange, className }: any) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });


  useEffect(() => {
    const container = containerRef.current;
    if (container !== null) {
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        theme: 'snow',
      });

      //ref.current = quill;

      if (defaultValueRef.current) {
        //quill.setContents(defaultValueRef.current);
        quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
      }

      //quill.on(Quill.events.TEXT_CHANGE, (...args: any[]) => {
      quill.on(Quill.events.TEXT_CHANGE, () => {
        //onTextChangeRef.current?.(...args);
        onTextChangeRef.current?.(quill.root.innerHTML);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args: any[]) => {
        onSelectionChangeRef.current?.(...args);
      });

      //quill.root.innerHTML = defaultValue;

      return () => {
        //ref.current = null;
        container.innerHTML = '';
      };
    } else {
      return () => {
        container!.innerHTML = '';
      }
    }
  }, []);

  return <div ref={containerRef} className={className}></div>;
}