import React, { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import { Register } from "./Register";
import { RegistrationSuccessful } from "./RegistrationSuccessful";
import { PasswordRestore } from "./PasswordRestore";
import { RTC } from "../rtc/RTC";
import { Xistics } from "../Xistics";
import { connection, User, withKeyAndJsonHeaders } from "../api";
import { TypedLoading, TypedLoadingState } from "../utils/Loading";
import { useBeta } from "../utils/useBeta";

//export type UserContextType = User;
export interface EnvironmentType {
    user: User;
    api: {
        key: string;
    };
}
export const EnvironmentContext = createContext<EnvironmentType | null>(null);
export const BetaContext = createContext<boolean>(false);

interface GreeterProps {
    reactVersion: string;
}
export function Greeter(props: GreeterProps) {
    console.debug(props.reactVersion);
    const [state, setState] = useState<TypedLoadingState<User | null, string>>({ state: 'pending' });

    const [beta, _] = useBeta();
    const u = User.getInstance(); // For TS checks

    useEffect(() => {
        const abortController = new AbortController();
        switch (state.state) {
            case 'pending':
                fetch("/api/v1/user", withKeyAndJsonHeaders({ signal: abortController.signal })).then(
                    async (response) => {
                        let user = null;
                        if (response.ok) {
                            user = await response.json();
                            setState({ state: 'success', data: user });
                        } else {
                            //setState({ state: 'error', error: "401 for fetching User" });
                            setState({ state: 'success', data: null })
                        }
                    },
                ).catch((e: DOMException) => {
                    console.debug("exception ", e);
                });
        }
        return () => {
            abortController.abort();
        }
    }, [state]);

    function onLoginSuccessful(_user: User) {
        console.debug("user is", _user)
        setState({ state: 'pending' });
    }

    function onClickLogout(_e: React.MouseEvent<HTMLElement>): void {
        console.debug(
            "logout headers are",
            withKeyAndJsonHeaders({
                method: "DELETE",
                body: JSON.stringify({ key: u.key }),
            }),
        );
        fetch(
            "/api/v1/logout",
            withKeyAndJsonHeaders({
                method: "DELETE",
                body: JSON.stringify({ key: u.key }),
            }),
        ).finally(() => {
            localStorage.clear();
            const user = User.getInstance();
            user.key = null;
            user.user_id = null;
            setState({ state: 'pending' });
        });
    }
    return (
        <TypedLoading state={state} error={(e: string) => { return (<>error loding data {e}</>); }} render={(user: User | null) => {
            if (user === null || u.key === null) {
                connection.disconnect();
                return (
                    <div className="container">
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Login onLoginSuccessful={onLoginSuccessful} />
                                }
                            />
                            <Route path="/register" element={<Register />} />
                            <Route
                                path="/registration-successful"
                                element={<RegistrationSuccessful />}
                            />
                            <Route
                                path="/passwordRestore"
                                element={<PasswordRestore />}
                            />
                            <Route path="/rtc" element={<RTC />} />
                            <Route path="/" element={<Navigate replace to="/" />} />
                        </Routes>
                    </div>
                );
            }
            connection.connect({ key: u.key });
            return (
                <EnvironmentContext.Provider
                    value={{ user: user, api: { key: u.key } }}
                >
                    <BetaContext.Provider value={beta}>
                        <Routes>
                            <Route
                                path="/*"
                                element={<Xistics onClickLogout={onClickLogout} />}
                            />
                        </Routes>
                    </BetaContext.Provider>
                </EnvironmentContext.Provider>
            );
        }} />
    );

}
