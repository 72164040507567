import "bootstrap";
import "../bx.sass";
import "../i18n";

import React, { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HashRouter } from "react-router-dom";
import { Greeter } from "./greeter/Greeter";

let container = $(".app-container")[0];

const root = ReactDOMClient.createRoot(container);

root.render(
    <StrictMode>
        <DndProvider backend={HTML5Backend}>
            <HashRouter>
                <Greeter reactVersion={React.version} />
            </HashRouter>
        </DndProvider>
    </StrictMode>,
);
