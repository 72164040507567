import React, { useContext } from "react";
import { XistTree } from "./XistTree";
import { XistPanel } from "./XistPanel";
import { ProjectContext } from "../ProjectPresenter";

interface XistTabProps {
    isProjectLead: boolean;
    readOnly: boolean;
}

export function XistsTab({ isProjectLead, readOnly }: XistTabProps) {
    const project = useContext(ProjectContext);
    if (project === null) {
        throw new Error("No project provided!");
    }
    const onClickOpenXist = (_id: number) => {
    }
    return (
        <div className="row h-100">
            <XistTree className="col-md-3 h-100 d-none d-md-block p-3 text-truncate overflow-auto" project={project} />
            <XistPanel className="col-12 col-md-9 h-100 overflow-auto" project={project} projectId={project.id} onClickOpenXist={onClickOpenXist} isProjectLead={isProjectLead} readOnly_={readOnly} />
        </div>
    );
}