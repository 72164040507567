/**
 * @author Steffen Kittel
 */
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Column, useTable } from 'react-table';

import Moment from 'react-moment';

import { Project, withKeyAndJsonHeaders, Xist, XistFile } from '../../api';

import './XistList.css';
import { Loading, LoadingState } from '../../utils/Loading';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { CreateXistWithFiles } from './creation/CreateXistWithFiles';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

//const XistIconNormal = "icon nomal"
//import { useTranslation } from 'react-i18next';

interface XistListItemProps {
    beta: boolean;
    onClickOpenXist_: any;
    projectId: number;
    xist: Xist;
}

export function XistListItem({ beta, onClickOpenXist_, projectId, xist }: XistListItemProps) {
    //const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState<LoadingState>(LoadingState.SUCCESS);
    const [edit, setEdit] = useState(false);
    const [newName, setNewName] = useState<string | undefined>();
    const abortController = new AbortController();

    useEffect(() => {
        return () => {
            abortController.abort()
        }
    }, []);

    const onClickEdit = (e: any) => {
        e.preventDefault();
        setEdit(true);
        setNewName(xist.name);
        return false;
    }

    const onClickOpenXist = (_e: any) => {
        onClickOpenXist_(xist.id);
    }

    const onClickRename = (e: any) => {
        e.preventDefault();
        setLoading(LoadingState.LOADING);
        fetch(`/api/v1/project/${projectId}/tasks/${xist.id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            signal: abortController.signal,
            body: JSON.stringify({
                title: newName
            })
        })).then(async (_res) => {
            setLoading(LoadingState.SUCCESS);
            setEdit(false);
        });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value);
    }

    const onClickCancel = (e: any) => {
        e.preventDefault();
        setEdit(false);
        return false;
    }

    const onClickDelete = (e: any) => {
        e.preventDefault();
        setLoading(LoadingState.LOADING);
        const changes = {
            "id": xist.id,
            "status": "-1"
        };
        fetch(`/api/v1/project/${projectId}/tasks/${xist.id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            signal: abortController.signal,
            body: JSON.stringify(changes)
        })).then(async (_res) => {
            setLoading(LoadingState.SUCCESS);
        });
    }

    const xistId = xist.id;
    if (edit) {
        return (
            <Loading state={loading}>
                <div>
                    <form>
                        <input className="form-control" name="newName" type="text" onChange={onChange}
                            value={newName} />
                        <a href="#" className="btn btn-success mb-2" onClick={onClickRename}>speichern</a>
                        <a href='#' className="btn btn-danger mb-2" onClick={onClickCancel}>abbrechen</a>
                    </form>
                </div>
            </Loading>
        )
    }
    return (
        <span className="xist-list-xist">
            {xist.show_project_name && <span className={"d-block small text-muted"}>{xist.project_name}</span>}
            {beta && <button className="btn btn-link border-0 material-icons p-0" onClick={() => {
                onClickOpenXist(xist)
            }}>
                comment
            </button>}
            <Link to={`/project/${projectId}/xist/${xistId}`}>{xist.name}</Link>
            <span className='options float-end'>
                <Link to={`/project/${projectId}/xist/${xistId}`} target="_blank"><i className='material-icons'>open_in_new</i></Link>
                <a href='#' onClick={onClickEdit}><i className='material-icons'>mode_edit</i></a>
                <a href='#' onClick={onClickDelete}><i className='material-icons'>delete</i></a>
            </span>
        </span>
    );
}
export type VersionId = number;
export interface IssueVersion {
    version_id: VersionId;
    timestamp: Date;
}
type IssueId = number;

export interface XistComment {
    id: number;
    task_id: number;
    comment: string;
    created: Date;
    user_name: string;
    user_surname: String;
    user_email: string;
    hidden: boolean;
    marked: boolean;
    deleted: boolean;
}

export enum XistStatus {
    PENDING = 0,
    IN_PROGRESS = 1,
    DONE = 2
}

export interface MyXist {
    id: number;
    xist_id: IssueId;
    parent_id: number;
    name: string;
    archived_at?: Date,
    directory: boolean;
    description: string;
    deadline: any;
    status: XistStatus;
    children: MyXist[];
    versions: IssueVersion[];
    version: VersionId;
    comments: XistComment[];
    changed: string;
    files: XistFile[];
}

interface XistListProps {
    beta: boolean;
    project: any;
    onClickOpenXist: any;
    isProjectLead: any;
    readOnly: boolean;
    xist: MyXist;
}
export function XistList({ beta, project, onClickOpenXist, isProjectLead, readOnly, xist }: XistListProps) {
    //const [t, i18n] = useTranslation();    
    //const [redirect, setRedirect] = useState(false);

    return (
        <div className="row">
            <List xist={xist} onClickOpenXist={onClickOpenXist} isProjectLead={isProjectLead} readOnly={readOnly} beta={beta} project={project} />
        </div>
    );
}
interface ListProps {
    xist: MyXist;
    onClickOpenXist: any;
    isProjectLead: boolean;
    readOnly: boolean;
    beta: boolean;
    project: Project;
}

function List({ xist, onClickOpenXist, isProjectLead, readOnly, project }: ListProps) {
    const [showArchived, setShowArchived] = useLocalStorage(`showArhived-${xist.id}`, false);
    const [showCompleted, setShowCompleted] = useLocalStorage(`showCompleted-${xist.id}`, true);
    const onChangeShowCompleted = (_e: any) => {
        setShowCompleted(!showCompleted);
    }

    const columns = React.useMemo<Column[]>(() => [
        {
            Header: '',
            id: 'directory',
            accessor: 'directory',
            Cell: (e: any) => {
                const xist = e.row.original;
                return xist.directory ?
                    <i className="material-icons">folder</i> :
                    <i className="material-icons">task</i>;
            },
            className: 'text-center',
            maxWidth: 10,
            width: 10
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: (e: any) => {
                const xist = e.row.original;
                return (
                    <XistListItem
                        beta={false}
                        key={xist.id}
                        projectId={project.id}
                        onClickOpenXist_={onClickOpenXist}
                        xist={xist}
                    />)
            }
        }, {
            Header: () => <div className='text-center'>Status</div>,
            accessor: 'status',
            Cell: (e: any) => {
                let xist = e.row.original;
                if (xist.status !== null) {
                    return <span className={`xist-status xist-status-${xist.status} text-center`}></span>
                }
                return (
                    <span className="xist-status xist-status-2 text-center"></span>
                )

            },
            maxWidth: 100
        }, {
            Header: () => <div className='text-center'>Fällig</div>,
            accessor: 'deadline',
            Cell: (e: any) => {
                const deadline = e.row.original.deadline;
                if (deadline !== null) {
                    return <Moment format="DD.MM.YYYY">{deadline}</Moment>
                }
                return <span>-</span>
            },
            className: 'text-center',
            maxWidth: 100
        }], []);

    let defaultSorted = [{ id: "directory", desc: true }, { id: "name", asc: true }];
    let [queryParams, _] = useSearchParams();
    //const queryParams = queryString.parse(history.location.search);
    let sorton = queryParams.get('sorton');
    if (sorton) {
        defaultSorted = [];
        for (let field of sorton.split(",")) {
            const column = field.split(";");
            if (column[1] == "desc") {
                defaultSorted.push({ id: column[0], desc: true });
            } else {
                defaultSorted.push({ id: column[0], asc: true });
            }
        }
    }

    /*const onSortedChange = (newSorted, column, shiftKey) => {
        const columns = [];
        for (let column of newSorted) {
            if (column.desc === true) {
                columns.push(column.id + ";" + "desc");
            } else if (undefined === column.asc || column.asc === true) {
                columns.push(column.id + ";" + "asc");
            }
        }
        /*history.replace({
            pathname: history.location.pathname,
            search: "?" + queryString.stringify({ sorton: columns.join(',') })
        });*/
    // ToDo Fix
    //throw new Error("Implement navigation")
    //};*/

    const isCompleted = (c: any) => {
        if (showCompleted) {
            return true;
        }
        if (c.directory === true) {
            return true;
        }
        return (c.status != XistStatus.DONE || c.directory == true)
    }

    let data = xist.children.filter(isCompleted).filter((c: MyXist) => c.directory === true ? true : showArchived ? true : c.archived_at === null);

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className="col-12">
            {isProjectLead && !readOnly && <div className="row">
                <p className="text-end col-12">
                    <Link className='btn btn-outline-primary btn-sm' to="createXist">Xist erstellen</Link>
                    &nbsp;
                    <Link className='btn btn-outline-primary btn-sm' to="createXistList">Xistsammlung erstellen</Link>
                </p>
            </div>}
            <CreateXistWithFiles project_id={project.id} parent_id={xist.xist_id} />
            <div className="row">
                <div className="col text-end">
                    <label>Archivierte anzeigen</label> <input type='checkbox' checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                    &nbsp;
                    <label>Erledigte anzeigen</label> <input type="checkbox" checked={showCompleted} onChange={onChangeShowCompleted} />
                </div>
            </div>
            <NoXists xist={xist} />
            <XistsCompleted xist={xist} />
            {data.length != 0 && (<table className='w-100' {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps()}>
                                        {
                                            column.render('Header')
                                        }
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>)}
        </div>
    );
}
interface XistsCompletedProps {
    xist: MyXist;
}

function XistsCompleted(props: XistsCompletedProps) {
    const completed = ((props.xist.children.length > 0) && (props.xist.children.filter((x: any) => {
        return x.status == 2
    }).length == props.xist.children.length));
    if (completed) {
        return (
            <Row>
                <Col className='text-center'>
                    <p>Alle Xists erledigt!</p>
                </Col>
            </Row>
        )
    }
    return null;
}

interface NoXistsProps {
    xist: MyXist;
}

function NoXists(props: NoXistsProps) {
    if (props.xist.children.length == 0) {
        return (
            <Row>
                <Col className='text-center'>
                    <p>Keine Xists vorhanden</p>
                </Col>
            </Row>
        )
    }
    return null;
}
