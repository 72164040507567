import React, { useReducer, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

//import NavbarIconWhite from "../../../assets/img/logo.png";
const NavbarIconWhite = "navbar_icon_white";

enum UserActionKind {
    SURNAME = 'surname',
    NAME = 'name',
    STREET = 'street',
    ZIP = 'zip',
    CITY = 'city',
    EMAIL = 'email',
    PASSWORD = 'password'
}

interface UserAction {
    type: UserActionKind;
    value: string;
}

export interface User {
    surname: string;
    name: string;
    street: string;
    zip: string;
    city: string;
    email: string;
    password: string;
}

function reducer(state: User, action: UserAction) {
    console.debug("user was", state)
    const { type, value } = action;
    switch (type) {
        case UserActionKind.SURNAME:
            return {
                ...state,
                'surname': value
            };
        case UserActionKind.NAME:
            return {
                ...state,
                'name': value
            };
        case UserActionKind.STREET:
            return {
                ...state,
                'street': value
            };
        case UserActionKind.ZIP:
            return {
                ...state,
                'zip': value
            };
        case UserActionKind.CITY:
            return {
                ...state,
                'city': value
            };
        case UserActionKind.EMAIL:
            return {
                ...state,
                'email': value
            };
        case UserActionKind.PASSWORD:
            return {
                ...state,
                'password': value
            };
        default:
            return state;
    }
}

export function Register() {
    const [user, dispatch] = useReducer(reducer, { surname: '', name: '', street: '', zip: '', city: '', email: '', password: '' });
    const [message, setMessage] = useState<string>("");
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetch(`/api/v1/user`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json; utf-8'
            },
            body: JSON.stringify({ ...user })
        }).then(
            async response => {
                const result = await response.json();
                if (result.error === "exists") {
                    setMessage("Benutzer existiert bereits.");
                } else {
                    const navigate = useNavigate();
                    //history.push('/registration-successful');
                    navigate('/registration-successful')
                }
            }
        )
    }

    return (
        <form className="form-horizontal" onSubmit={onSubmit}>
            <div className="modal-dialog shadow-sm" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="welcome-h4 my-auto">Registrieren bei xistics</h2><img
                            className="pull-right welcome-logo border border-dark" src={NavbarIconWhite}
                            style={{ width: '48px', height: '48px' }} />
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="control-label">Vorname</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="text" name="surname"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.SURNAME, value: e.currentTarget.value })} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Nachname</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="text" name="name"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.NAME, value: e.currentTarget.value })} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Straße</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="text" name="street"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.STREET, value: e.currentTarget.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label className="control-label">Postleitzahl</label>
                                    <div className="controls">
                                        <input className="form-control form-control-sm" type="text" name="zip"
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.ZIP, value: e.currentTarget.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label className="control-label">Ort</label>
                                    <div className="controls">
                                        <input className="form-control form-control-sm" type="text" name="city"
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.CITY, value: e.currentTarget.value })} />
                                    </div>
                                </div></div></div>
                        <div className="form-group">
                            <label className="control-label">E-Mail</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="email" name="email"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.EMAIL, value: e.currentTarget.value })} required />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Passwort</label>
                            <div className="controls">
                                <input className="form-control form-control-sm" type="password" name="password"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({ type: UserActionKind.PASSWORD, value: e.currentTarget.value })} required /></div>
                        </div>
                        {message}
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn btn-danger">Abbrechen</Link>
                        <button className="btn btn-success">Registrieren</button>
                    </div>
                </div>
            </div>
        </form>
    );
}