/**
 * @author Steffen Kittel
 */
import React, { useEffect, useState } from "react";

import { connection, withKeyAndJsonHeaders } from "../../api";

import { MyXist, VersionId } from "./XistList";
import { XistBreadcrumbs } from "./XistBreadcrumbs";

import { Xist } from "./Xist";
import { TypedLoading, TypedLoadingState } from "../../utils/Loading";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { CreateXist } from "./creation/CreateXist";

export interface ChangedEvent {
    id: number;
    xist_id: number;
    changed: VersionId;
}

export type ChangedCallback = (version: ChangedEvent) => void;

interface XistPanelProps {
    className: any;
    readOnly_: boolean;
    onClickOpenXist: any;
    project: any;
    isProjectLead: boolean;
    projectId: number;
}

export function XistPanel({
    className,
    readOnly_,
    onClickOpenXist,
    project,
    isProjectLead,
    projectId,
}: XistPanelProps) {
    const [state, setLoadingState] = useState<TypedLoadingState<MyXist, string>>({ state: 'pending' });
    const [xist, saveXist] = useState<MyXist | null>(null);
    const [version, setVersion] = useState<VersionId | null>(null);
    const [readOnly, _setReadOnly] = useState(readOnly_);
    const refresh = (version: any) => {
        let v = JSON.parse(version);
        setVersion(v.version.version_id);
    };
    const params = useParams();
    let xistId = 0;
    if (undefined !== params.xistId) {
        let temp = parseInt(params.xistId);
        if (!isNaN(temp)) {
            xistId = temp;
        }
    }

    console.debug("panel xist id is ", xistId)
    useEffect(() => {
        setLoadingState({ state: 'pending' });
        connection.on(`update-xist-${xistId}`, refresh);
        const abortController = new AbortController();
        let urlSearchParams = new URLSearchParams();
        if (version) {
            urlSearchParams.append("version", version.toString());
        }

        fetch(
            `/api/v1/project/${projectId}/tasks/${xistId}?${urlSearchParams.toString()}`,
            withKeyAndJsonHeaders({
                signal: abortController.signal,
            }),
        )
            .then(async (response) => {
                const xist = await response.json();
                setLoadingState({ state: 'success', data: xist })
            })
            .catch(() => { });
        return () => {
            connection.off(`update-xist-${xistId}`, refresh);
            abortController.abort();
        };
    }, [project, version, xistId]);

    useEffect(() => {
        if (xist === null) return;
        setLoadingState({ state: 'loading', data: xist });
        const abortController = new AbortController();
        const { signal } = abortController;
        async function streamingtest(xist: MyXist) {
            const response = await fetch(`/api/v1/project/${project.id}/tasks/${xist.xist_id}`, withKeyAndJsonHeaders({
                method: 'PUT',
                signal,
                body: JSON.stringify(xist)
            }));
            if (response.body) {
                //const decoder = new åTextDecoder();
                const streamReader = response.body.pipeThrough(new TextDecoderStream());
                const reader = streamReader.getReader();
                try {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        //const chunk = decoder.decode(value, { stream: true });
                        const line = value;
                        try {
                            const jsonObject = JSON.parse(line);
                            setVersion(jsonObject.version.version_id);
                        } catch (error) {
                            console.debug("babuba", error);

                        }
                    }
                } finally {
                    reader.releaseLock();
                }
            }
        }
        streamingtest(xist);
        return () => {
            abortController.abort();
        }
    }, [xist]);
    const navigate = useNavigate();
    return (
        <div className={className} key={xistId}>
            <div className="row">
                <div className="col mx-3 mb-3 py-3 shadow-sm">
                    <TypedLoading<MyXist, string> state={state} render={(xist) => {
                        return (
                            <>
                                <XistBreadcrumbs
                                    xists={project.tasks}
                                    xist={xist}
                                    onClickOpenXist={onClickOpenXist}
                                    projectId={projectId}
                                />
                                <Routes>
                                    <Route
                                        path="/createXist"
                                        element={
                                            <CreateXist
                                                parent_id={xistId}
                                                directory={false} // ToDo: Ist glaube nicht richtig
                                                project={project}
                                                onChange={() => { }}
                                                onCancel={() => {
                                                    navigate("..");
                                                }}
                                                onSuccess={(issue_id: number, _issue_version_id: number) => {
                                                    navigate(`../xist/${issue_id}`);
                                                }}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/createXistList"
                                        element={
                                            <CreateXist
                                                parent_id={xistId}
                                                project={project}
                                                onChange={() => { }}
                                                onCancel={() => {
                                                    navigate(-1);
                                                }}
                                                //onCancel={() => setMode('list')} // ToDo: Redirect
                                                onSuccess={(issue_id: number, _issue_version_id: number) => {
                                                    navigate(`../xist/${issue_id}`);
                                                }}
                                                directory={true}
                                            />
                                        }
                                    />
                                    <Route path="/" element={
                                        <Xist
                                            key={`${xist.xist_id}-${xist.version}`}
                                            saveXist={saveXist}
                                            version={version}
                                            setVersion={setVersion}
                                            isProjectLead={isProjectLead}
                                            onClickOpenXist={onClickOpenXist}
                                            project={project}
                                            remoteXist={xist}
                                            readOnly={readOnly}
                                        />}
                                    />
                                    }
                                </Routes>
                            </>
                        )
                    }}
                    />
                </div>
            </div>
        </div>
    );
}
