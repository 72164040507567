import React, { useContext } from 'react';
import { ProjectContext } from '../ProjectPresenter';


export function ProjectRoles() {
    const project = useContext(ProjectContext);
    if (project === null) {
        return (<>"Fehler, kein Projekt vorhanden!"</>);
    }
    return (
        <>
            <h1>Rollen</h1>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Bezeichnung</th>
                        <th>Beschreibung</th>
                        <th>Eigentümer</th>
                        <th>Projektleiter</th>
                        <th>Mitarbeiter</th>
                        <th>Beobachter</th>
                    </tr>
                </thead>
                <tbody>
                    {project.roles.map((role) => {
                        return (
                            <tr key={role.id}>
                                <td>{role.name}</td>
                                <td>{role.description}</td>
                                <td><CheckBlock bool={role.owner} /></td>
                                <td><CheckBlock bool={role.project_lead} /></td>
                                <td><CheckBlock bool={role.staff} /></td>
                                <td><CheckBlock bool={role.bystander} /></td>
                            </tr>);
                    })}
                </tbody>
            </table>
        </>
    )
}

function CheckBlock({ bool }: { bool: boolean }) {
    return (
        <span className='material-icons'>{bool === true ? "check" : "block"}</span>
    )
}